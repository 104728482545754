import { takeLatest,put } from "redux-saga/effects";
import url from 'services/urls.json';
import { postMethod } from "services/apiServices";
import {ANONYMOUS , ANONYMOUS_ERROR , ANONYMOUS_SUCCESS} from './constant';
import { ClearTokenRedirectLogin } from "context/context";

function* anonymousApi(action) {
 
    try {
        const response = yield postMethod(url.anonymous,action.data);
        if(response.status === 200 || response.status === 201){
            yield put({type:ANONYMOUS_SUCCESS , payload : response});
            
            if (action.external && action.external.run === true) {
                action.external.handleFunction(response);
            }
        }
        else {
            yield put({type:ANONYMOUS_ERROR , payload : response});
            if(response.status === 401 || response.status === 403){
              ClearTokenRedirectLogin(action.external.navigate)
            }
            if(response.status === 406){
                action.external.handleError(response);
              }
            if (action.external && action.external.toast) {
                action.external.handleToastError(response)
            }
            // action.external.handleError(response);
        }
    }
    catch(error){
        yield put({type:ANONYMOUS_ERROR,payload:error})
    }
}

function* anonymousSaga(){
    yield takeLatest(ANONYMOUS , anonymousApi)
}

export default anonymousSaga;
