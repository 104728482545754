export const setToken = (token)=>{
    localStorage.setItem("token",token)
}
export const getToken = ()=>{
    return localStorage.getItem("token")
}

export const validateEmail = (email) => {
    // Basic email regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
};

export const validatePhoneNumber = (phone) => {
    // Example: Validates a 10-digit phone number
    const phoneRegex = /^\+?\d{10,15}$/; // Adjust regex as needed for your use case
    return phoneRegex.test(phone) ;
    // ? '' : 'Please enter a valid phone number (10 to 15 digits)';
};

export const formatCardNumber = (value) => {
  // Remove all non-digit characters
  const digits = value.replace(/\D/g, '').slice(0, 16);
  // Insert space after every 4 digits
  const formatted = digits.replace(/(.{4})/g, '$1 ').trim();
  return formatted;
};


export const isValidCardNumber = (number) => {
    const cleaned = number.replace(/\D/g, '');
    // Basic Luhn algorithm check
    let sum = 0;
    let alternate = false;
    for (let i = cleaned.length - 1; i >= 0; i--) {
      let n = parseInt(cleaned.charAt(i), 10);
      if (alternate) {
        n *= 2;
        if (n > 9) n -= 9;
      }
      sum += n;
      alternate = !alternate;
    }
    return sum % 10 === 0 && cleaned.length === 16;
  };
 
  // Helper function to format expiry date
export const formatExpiry = (value) => {
  // Remove all non-digit characters
  const digits = value.replace(/\D/g, '').slice(0, 4);
  if (digits.length > 2) {
    return `${digits.slice(0, 2)}/${digits.slice(2)}`;
  }
  return digits;
};
  export const isValidExpiryDate = (expiry) => {
    const [month, year] = expiry.split('/');
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed
    const currentYear = currentDate.getFullYear() % 100; // Get last two digits of the year
    return (
      month >= 1 &&
      month <= 12 &&
      year?.length === 2 &&
      (parseInt(year) > currentYear || (parseInt(year) === currentYear && parseInt(month) >= currentMonth))
    );
  };
  
  export  const formatCvv = (value)=>{
    return value.replace(/\D/g, '').slice(0, 4)
 }
  export const isValidCVV = (cvv) => {
    return /^\d{3,4}$/.test(cvv);
  };
  
  export const containerVariants = {
    hidden: { opacity: 0 }, // Fully transparent initially
    visible: { 
        opacity: 1, // Fully visible when shown
        transition: { duration: 0.5 } // Adjust duration for fade-in speed
    },
    exit: { 
        opacity: 0, // Fades out when component is removed
        transition: { duration: 0.5 } // Adjust duration for fade-out speed
    }
};