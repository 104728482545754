import React, { useEffect, useState } from "react";
import { Container, Row, Col, Stack, Button } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PerfectScooter from "components/common/perfectScooter/perfectScooter";
import "pages/Buy/Buy.scss";
import url from 'services/urls.json';
import { getMethod, getMethodWithToken, postMethod, postMethodWithToken } from "services/apiServices";
import { useNavigate } from "react-router-dom";
import cartCallApi from "store/cart/action";
import { CART } from "store/cart/constant";
import { commonCallApi } from "store/common/action";
import { COMMON_DETAILS } from "store/common/constant";
import { useDispatch, useSelector } from "react-redux";
import userCallApi from "store/user/action";
import { USER } from "store/user/constant";
import { toast } from "react-toastify"
import { ClearTokenRedirectLogin } from "context/context";
import H1text from "components/H1text/H1text";
import Ptext from "components/Ptext/Ptext";
import { Box, Skeleton } from "@mui/material";
import styled from "styled-components";
import VehicleShow from "components/VehicleShow/VehicleShow";
import { containerVariants, getToken } from "services/common";
import { motion } from "framer-motion"; 

const WrapperImageDiv = styled.div`
  background: url(${props => props.image});
  height: 346px;
  max-width:346px;
  margin:auto;
  marginTop: 20px;
  cursor:pointer;
  background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
`;
const Buy = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeIndextwo, setActiveIndextwo] = useState(0);
    const [id, setId] = useState('')
    const [order, setOrder] = useState(0)
    const [productList, setProductList] = useState([])
    const [productId, setProductId] = useState('')
    const [firstCarousel, setFirstCarousel] = useState('')
    const [carouselItem, setCarouselItem] = useState([]);
    const [activeIndices, setActiveIndices] = useState({});


    useEffect(() => {
        buy()
    }, []);

    const handleProductId = (id) => {
        setProductId(id)
    };

    const [loader, setLoader] = useState(false)

    const buy = async () => {
        setLoader(true);
        let apiUrl = `${url.buy}?catagory=scooter&paginate=false&page_limit=0`;
        let response;
        response = localStorage.getItem("token") ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl)
        if (response.status === 200 || response.status === 201) {
            setProductList(response.data)
            setLoader(false);
            const allCarouselIndex = response?.data?.length > 0 && response.data.map((item, index) => {
                // index === 0 ? setFirstCarousel(item?.id) : index === 1 ? setSecondCarousel(item?.id) : ''
                return (
                    { "carouselId": item?.id }
                )

            });
            setCarouselItem(allCarouselIndex);
        }
        else if (response.status === 401 || response.status === 403) {
            ClearTokenRedirectLogin(navigate)
            setLoader(false);
        }
    }

    const [isModalActive, setIsModalActive] = useState(false);
    const handleClose = () => setIsModalActive(false);
    const [selectedProduct, setSelectedProduct] = useState({});

    
    const [addCartloader, setAddCartloader] = useState(false);

    const handleAddCart = async (product) => {
        setAddCartloader(true);
        setSelectedProduct(product);
        let body = {
            "url": product.url,
            "quantity": 1
        }


        let response = localStorage.getItem("token") ? await postMethodWithToken(url.addCart, body) : await postMethod(url.addCart, body)
            if (response.status === 200 || response.status === 201) {
                const basket = response.data.lines.split('/api/v1/')[1]
                toast.success("Item Added to cart");
                localStorage.setItem("basketId",response?.data?.id);
                if(getToken()){
                    dispatch(userCallApi(USER, {}, {
                        run: true,
                        handleFunction: (res) => {handleCartCount(res?.data?.basket_id);}
                    }))
                }
                setIsModalActive(true);
                localStorage.setItem('cartAPI', basket);
                localStorage.setItem("clearAllCart", response.data.url);
                setAddCartloader(false);
            }
            // else if (response.status === 401) {
            //     navigate('/signin');
            //     setAddCartloader(false);
            // }
            else if (response.status === 406) {
                toast.error("Out Of Stock");
                setAddCartloader(false);
            }else{
                setAddCartloader(false);
            }
        // })

    }
    const handleCart = (basketId) => {
        dispatch(cartCallApi(CART, {}, {
            url: `ecom/baskets/${basketId}/lines`,
            run: true,
            handleFunction: (res) => handleCartCount(res)
        }))
    };
    const handleCartCount = (basketId) => {
        dispatch(cartCallApi(CART, {}, {
            url: `ecom/baskets/${basketId}/lines`,
            run: true,
            handleFunction: (res) => handleSuccess(res)
        }))
    };

    const handleSuccess = (res) => {
        dispatch(commonCallApi(COMMON_DETAILS, { cart: res?.data?.[0]?.total_quantity }, { run: true }))
    };

    const handleSelect = (index, id) => {
        setActiveIndices((prevIndices) => ({
            ...prevIndices,
            [id]: index,
        }));
    };

    const [Models, setModels] = useState([])


    useEffect(() => {
        handleModels();
    }, [])
    const handleModels = async () => {
        let apiUrl = `${url.buy}?catagory=scooter&paginate=false&page_limit=0`;
        let response;
        response = localStorage.getItem("token") ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl)
        if (response.status === 200 || response.status === 201) {
            setModels(response.data)

        }
    }
    return (
        <motion.div
        variants={containerVariants}
            initial="hidden"
            animate="visible" className="Buy ps-3 pe-3 " style={{ background: '#fafafa' }}>
            <Container className="p-0" style={{ marginBottom: "48px" }}>
                <Row>
                    <Col md={12}>
                        <Box sx={{
                            marginTop: {
                                xs: '16px',  
                                sm: '32px',  
                                md: '48px',  
                                lg: '64px',  
                            },
                        }}>
                            <H1text size='36' lineHeight={'36'} fontWeight='600' className="text-black fw-700" marginTop={'0'}>Which scooter is right for you?</H1text>
                        </Box>
                    </Col>
                </Row>
                <Box sx={{
                            marginTop: {
                                xs: '16px',  
                                sm: '32px',  
                                md: '48px',  
                            },
                        }}>
                <Row  className="gx-2 gy-3" >
                    <VehicleShow
                        productList={productList}
                        activeIndices={activeIndices}
                        onClick={(e) => handleAddCart(e)}
                        handleSelect={(i, id) => handleSelect(i, id)}
                        isModalActive={isModalActive}
                        handleClose={handleClose}
                        selectedProduct={selectedProduct}
                        type={'scooter'}
                        loader={loader}
                        addCartloader={addCartloader}

                    />
                </Row>
                </Box>
            </Container>
            <Box 
            sx={{
                marginTop: {
                  xs: '12px',
                  sm: '24px',
                  md: '48px',
                  lg:'96px'
                },
              }}
            >
                <PerfectScooter
                    heading={'Your own perfect scooter'}
                    for={'Scooter'}
                    image={'https://metrorides.theclientdemos.com/assets/images/cta.png'}
                    top={'-96px'}
                    models={Models}
                />
            </Box>
        </motion.div>
    );
};

export default Buy;


