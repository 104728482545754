import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import "../footer/footer.scss";
import { getMethod , getMethodWithToken } from "services/apiServices";
import url from 'services/urls.json';
const Footer = () => {
    const [Models,setModels] = useState([])
    const Store = ["Buy", "Rent"];
    const Aboutus = ["Our story", "Help center", "Contacts"];


    useEffect(()=>{
        handleModels()
    },[])
    const handleModels=async()=>{
        let apiUrl = `${url.buy}?catagory=scooter&paginate=false&page_limit=0`;
        let response;
        response = localStorage.getItem("token") ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl)
         if(response.status === 200 || response.status === 201){
            setModels(response.data)

         }
    }

    return (
        <footer className="bg-dark text-light footer" style={{  width: '100%' }}>
            <Container className="py-3">
                <Row>
                    <Col md={2}>
                        <div className="footerLogo">
                            <a href="#">
                                <img src=" https://metrorides.theclientdemos.com/assets/images/flogo.svg" alt="logo" />
                            </a>

                        </div>
                    </Col>
                    <Col md={10}>
                        <Row>
                            <Col md={3}>
                                <div className="footerWidgets">
                                    <h6 className="text-white mb-4 headingFooter">Models</h6>
                                    <div className="footerLinks">
                                        {Models.length > 0 && Models.map((data, index) => {
                                            return (
                                                <a key={index} href="#" className="links text-decoration-none mt-2" style={{ fontSize: '14px' }}>{data?.title}</a>
                                            )
                                        })}
                                    </div>

                                </div>
                            </Col>
                            <Col md={2}>
                                <div className="footerWidgets">
                                    <h6 className="text-white mb-4 fw-500 headingFooter">Store</h6>
                                    <div className="footerLinks">
                                        {Store.length > 0 && Store.map((data, index) => {
                                            return (
                                                <a key={index} href="#" className=" links text-decoration-none mt-2" style={{ fontSize: '14px' }}>{data}</a>
                                            )
                                        })}
                                    </div>

                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="footerWidgets">
                                    <h6 className="text-white mb-4 headingFooter">About us</h6>
                                    <div className="footerLinks">
                                        {Aboutus.length > 0 && Aboutus.map((data, index) => {
                                            return (
                                                <a key={index} href="#" className="links text-decoration-none mt-2" style={{ fontSize: '14px' }}>{data}</a>
                                            )
                                        })}
                                    </div>

                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="footerWidgets">
                                    <h6 className="text-white mb-4 headingFooter">Stay in touch</h6>
                                    <div className="footerLinks">
                                        <p className="links mt-2" style={{ fontSize: '14px' }}>Sign up for Metro for regular updates on smartest way to get around the City</p>
                                    </div>
                                    <form className="newsletter relative">
                                        <input type="email" placeholder="your email" />
                                        <button style={{ background: 'white' }} className="email">
                                            <img src="https://metrorides.theclientdemos.com/assets/images/paperplane.svg" />
                                        </button>
                                    </form>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={6}>
                        <div className="footerWidgets">
                            <p className="copy_right">© 2023 Metro Rides | All right reserved </p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="footerWidgets d-flex justify-content-end align-items-center">
                            <p className="copy_right"><span> made by</span> <a href="#" className="text-white text-decoration-none fw-500">roobinium.io</a></p>
                        </div>
                    </Col>

                </Row>
            </Container>
        </footer>
    )
}

export default Footer

