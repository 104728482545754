import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

function CommonModal({
  width = 862,
  padding = '32',
  handleClose = () => {},
  modalActive,
  innerContent,
}) {
  const style = {
    width: width ? width : '100%',
    height: 'fit-content',
    maxWidth: 1260,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '16px',
    padding: padding,
    '@media (max-width: 767px)': {
      padding: '10px', // Set padding to 10px on small screens
      margin: '10px',
    },
  };

  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'scroll',
        '@media (max-width: 900px)': {
          alignItems: 'flex-start !important', // Adjust for xs breakpoint
        },
      }}
      open={modalActive}
      onClose={handleClose} // Trigger the handleClose when clicking outside the modal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
    >
      <Box sx={style}>
        {innerContent}
      </Box>
    </Modal>
  );
}

export default CommonModal;
