import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import styled from "styled-components";
import Ptext from "components/Ptext/Ptext";
import { Box } from "@mui/material";
import theverge from "assets/images/theverge.png";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import placeholderImage from 'assets/images/placeholderImage.png';
import H1text from "components/H1text/H1text";
import { useEffect } from "react";

const WrapperDiv = styled.div`
  position: relative;
  width: 100%; /* Ensure the wrapper takes full width */

  /* Arrow Buttons */
  .slick-prev, .slick-next {
    display: flex; /* Center the icon */
    align-items: center; 
    justify-content: center;
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    z-index: 2; /* Ensure buttons are above slides */
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
  }

  .slick-prev:before, .slick-next:before {
    display: none; /* Hide default arrows */
  }

  .slick-prev {
    left: -45px; /* Default position */
  }

  .slick-next {
    right: -45px; /* Default position */
  }

  /* Custom Arrow Icons */
  .custom-prev-icon, .custom-next-icon {
    color: #000000;
    font-size: 24px;
    transition: color 0.3s;
  }

  /* Fade effect when arrows are disabled */
  .fade {
    color: rgba(0, 0, 0, 0.5);
    cursor: default;
  }

  /* Responsive Adjustments */
  @media (max-width: 1629px) {
    .slick-prev {
      left: -35px;
    }

    .slick-next {
      right: -35px;
    }
  }

  @media (max-width: 1230px) {
    .slick-prev {
      left: -30px;
    }

    .slick-next {
      right: -30px;
    }
  }

  @media (max-width: 880px) {
    .slick-prev {
      left: -25px;
    }

    .slick-next {
      right: -25px;
    }
  }

  @media (max-width: 700px) {
    .slick-prev, .slick-next {
      width: 35px;
      height: 35px;
    }

    .slick-prev {
      left: 10px; /* Move inside the carousel */
    }

    .slick-next {
      right: 10px; /* Move inside the carousel */
    }
  }

  @media (max-width: 480px) {
    .slick-prev, .slick-next {
      display: none; /* Optionally hide arrows on very small screens */
    }
  }
`;

export default function CustomCarousel(props) {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(5);
  // const totalSlides = 5; // Total number of slides

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: props.slidesToShow,
    slidesToScroll: 1,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    responsive: [
      {
        breakpoint: 1629,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1230,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 880,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    setTotalSlides(props?.data?.length);
  },[])
  
  const MAX_LENGTH = 70;
  const [expandedItems, setExpandedItems] = useState({});

  const toggleReadMore = (id) => {
    setExpandedItems((prev) => ({
      ...prev,
      [id]: !prev[id]
    }));
  };
  return (
    <WrapperDiv>
      {props.useFor == "Reviews" &&
        <Slider ref={sliderRef} {...settings}>
          {props?.data?.length > 0 && props?.data?.map((item, index) => (
            < >
              <Box margin='0 8px' bgcolor={"#fbfbfd"} borderRadius='24px' padding="66px 32px 32px 32px" display={'flex'} alignItems={'center'} flexDirection={'column'}>
                {/* <img src={theverge} style={{ maxHeight: '35px', maxWidth: '210px' }} /> */}
                <H1text size="46" lineHeight={"24"} fontWeight={"500"} marginTop='0' fontFamily="SquadaOne">{item?.company}</H1text>
                <Box>
                  <Ptext size="14" lineHeight="24" fontWeight="500" marginTop="65" style={{ maxWidth: '296px' }}>
                    {expandedItems[item.id] ? item?.review_text : item?.review_text?.length > MAX_LENGTH
                      ? item.review_text.substring(0, MAX_LENGTH) + '...'
                      : item.review_text}
                  </Ptext>
                  {item?.review_text?.length > MAX_LENGTH && (
                    <span
                      style={{ color: '#1717db', cursor: 'pointer' }}
                      onClick={() => toggleReadMore(item.id)}
                    >
                      {expandedItems[item.id] ? 'Read less' : 'Read more'}
                    </span>
                  )}
                </Box>
              </Box>
            </>
          ))}
        </Slider>
      }

      {props.useFor == "Rider" &&
        <Slider ref={sliderRef} {...settings}>
          {props?.data?.length > 0 && props?.data?.map((item, index) => (
            <div key={index}>
              <Box margin='0 8px' bgcolor={"#ffffff"} borderRadius='24px' padding="48px 0px 32px 0px" display={'flex'} alignItems={'center'} flexDirection={'column'}>
                <Box
                  sx={{
                    backgroundImage: `url(${item?.profile_image ? item?.profile_image : placeholderImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '50%',
                    height: '128px',
                    width: '128px',
                  }}
                >
                  {/* <img src={item?.profile_image} alt="" /> */}
                </Box>
                <Ptext size="20" lineHeight={"28"} fontWeight={"600"} marginTop='24' style={{ maxWidth: '296px' }}>{item?.first_name}</Ptext>
              </Box>
            </div>
          ))}
        </Slider>
      }


      {props.useFor == "Feedback" &&
        <Slider ref={sliderRef} {...settings}>
          <div >
            <Box margin='0 8px' bgcolor={"#fbfbfd"} borderRadius='24px' padding="32px" display={'flex'} alignItems={'start'} flexDirection={'column'}>
              <Box display='flex'>
                <Box bgcolor={'grey'} borderRadius={'50%'} height={'64px'} width={'64px'} marginRight='24px'></Box>
                <Box display={'flex'} flexDirection='column' alignItems={'start'}>
                  <Ptext size="18" lineHeight={"28"} fontWeight={"600"} marginTop='0' >Robert Shultz</Ptext>
                  <Box>
                    <Ptext size="14" lineHeight={"24"} fontWeight={"600"} marginTop='8' style={{ borderRadius: '28px', background: "#f2f2f4", padding: '2px 12px', display: 'inline-block' }} >5/5</Ptext>
                  </Box>
                </Box>
              </Box>
              <Ptext size="14" lineHeight={"24"} fontWeight={"500"} marginTop='24' textAlign={'start'} color="#1d1d1d">Love this product so much! It has transformed my commute into an enjoyable part of my day. Will be buying a backup onc...</Ptext>
              <Ptext size="16" lineHeight={"24"} fontWeight={"500"} marginTop='24' textAlign={'start'} color="#4579F5">Read more <NavigateNextIcon fontSize="small" /></Ptext>
            </Box>
          </div>
          <div >
            <Box margin='0 8px' bgcolor={"#fbfbfd"} borderRadius='24px' padding="32px" display={'flex'} alignItems={'start'} flexDirection={'column'}>
              <Box display='flex'>
                <Box bgcolor={'grey'} borderRadius={'50%'} height={'64px'} width={'64px'} marginRight='24px'></Box>
                <Box display={'flex'} flexDirection='column' alignItems={'start'}>
                  <Ptext size="18" lineHeight={"28"} fontWeight={"600"} marginTop='0' >Robert Shultz</Ptext>
                  <Box>
                    <Ptext size="14" lineHeight={"24"} fontWeight={"600"} marginTop='8' style={{ borderRadius: '28px', background: "#f2f2f4", padding: '2px 12px', display: 'inline-block' }} >5/5</Ptext>
                  </Box>
                </Box>
              </Box>
              <Ptext size="14" lineHeight={"24"} fontWeight={"500"} marginTop='24' textAlign={'start'} color="#1d1d1d">Love this product so much! It has transformed my commute into an enjoyable part of my day. Will be buying a backup onc...</Ptext>
              <Ptext size="16" lineHeight={"24"} fontWeight={"500"} marginTop='24' textAlign={'start'} color="#4579F5">Read more <NavigateNextIcon fontSize="small" /></Ptext>
            </Box>
          </div>
          <div >
            <Box margin='0 8px' bgcolor={"#fbfbfd"} borderRadius='24px' padding="32px" display={'flex'} alignItems={'start'} flexDirection={'column'}>
              <Box display='flex'>
                <Box bgcolor={'grey'} borderRadius={'50%'} height={'64px'} width={'64px'} marginRight='24px'></Box>
                <Box display={'flex'} flexDirection='column' alignItems={'start'}>
                  <Ptext size="18" lineHeight={"28"} fontWeight={"600"} marginTop='0' >Robert Shultz</Ptext>
                  <Box>
                    <Ptext size="14" lineHeight={"24"} fontWeight={"600"} marginTop='8' style={{ borderRadius: '28px', background: "#f2f2f4", padding: '2px 12px', display: 'inline-block' }} >5/5</Ptext>
                  </Box>
                </Box>
              </Box>
              <Ptext size="14" lineHeight={"24"} fontWeight={"500"} marginTop='24' textAlign={'start'} color="#1d1d1d">Love this product so much! It has transformed my commute into an enjoyable part of my day. Will be buying a backup onc...</Ptext>
              <Ptext size="16" lineHeight={"24"} fontWeight={"500"} marginTop='24' textAlign={'start'} color="#4579F5">Read more <NavigateNextIcon fontSize="small" /></Ptext>
            </Box>
          </div>
          <div >
            <Box margin='0 8px' bgcolor={"#fbfbfd"} borderRadius='24px' padding="32px" display={'flex'} alignItems={'start'} flexDirection={'column'}>
              <Box display='flex'>
                <Box bgcolor={'grey'} borderRadius={'50%'} height={'64px'} width={'64px'} marginRight='24px'></Box>
                <Box display={'flex'} flexDirection='column' alignItems={'start'}>
                  <Ptext size="18" lineHeight={"28"} fontWeight={"600"} marginTop='0' >Robert Shultz</Ptext>
                  <Box>
                    <Ptext size="14" lineHeight={"24"} fontWeight={"600"} marginTop='8' style={{ borderRadius: '28px', background: "#f2f2f4", padding: '2px 12px', display: 'inline-block' }} >5/5</Ptext>
                  </Box>
                </Box>
              </Box>
              <Ptext size="14" lineHeight={"24"} fontWeight={"500"} marginTop='24' textAlign={'start'} color="#1d1d1d">Love this product so much! It has transformed my commute into an enjoyable part of my day. Will be buying a backup onc...</Ptext>
              <Ptext size="16" lineHeight={"24"} fontWeight={"500"} marginTop='24' textAlign={'start'} color="#4579F5">Read more <NavigateNextIcon fontSize="small" /></Ptext>
            </Box>
          </div>
          <div >
            <Box margin='0 8px' bgcolor={"#fbfbfd"} borderRadius='24px' padding="32px" display={'flex'} alignItems={'start'} flexDirection={'column'}>
              <Box display='flex'>
                <Box bgcolor={'grey'} borderRadius={'50%'} height={'64px'} width={'64px'} marginRight='24px'></Box>
                <Box display={'flex'} flexDirection='column' alignItems={'start'}>
                  <Ptext size="18" lineHeight={"28"} fontWeight={"600"} marginTop='0' >Robert Shultz</Ptext>
                  <Box>
                    <Ptext size="14" lineHeight={"24"} fontWeight={"600"} marginTop='8' style={{ borderRadius: '28px', background: "#f2f2f4", padding: '2px 12px', display: 'inline-block' }} >5/5</Ptext>
                  </Box>
                </Box>
              </Box>
              <Ptext size="14" lineHeight={"24"} fontWeight={"500"} marginTop='24' textAlign={'start'} color="#1d1d1d">Love this product so much! It has transformed my commute into an enjoyable part of my day. Will be buying a backup onc...</Ptext>
              <Ptext size="16" lineHeight={"24"} fontWeight={"500"} marginTop='24' textAlign={'start'} color="#4579F5">Read more <NavigateNextIcon fontSize="small" /></Ptext>
            </Box>
          </div>
        </Slider>
      }





      <div className="slick-prev">
        <ArrowBackIosIcon
          className={`custom-prev-icon ${currentSlide === 0 ? 'fade' : ''}`}
          onClick={() => sliderRef.current.slickPrev()}
        />
      </div>
      <div className="slick-next">
        <ArrowForwardIosIcon
          className={`custom-next-icon ${currentSlide === totalSlides - Math.ceil(settings.slidesToShow) ? 'fade' : ''}`}
          onClick={() => sliderRef.current.slickNext()}
        />
      </div>
    </WrapperDiv>
  );
}
