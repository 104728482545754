import { takeLatest,put } from "redux-saga/effects";
import { postMethodWithToken } from "services/apiServices";
import url from 'services/urls.json';
import { ClearTokenRedirectLogout } from "context/context";
import { LOGOUT,LOGOUT_ERROR,LOGOUT_SUCCESS } from "./constant";

function* getLogOutApi(action){
    try{
        const response = yield postMethodWithToken(url.logOut)
        if(response.status === 200 || response.status === 201){
            yield put({type:LOGOUT_SUCCESS,payload:response})
            ClearTokenRedirectLogout(action.external.navigate)
            if(action.external && action.external.run ===true){
                
                
                // localStorage.removeItem('token')
                action.external.handleFunction(response)
            }
        }
        else {
            yield put({type:LOGOUT_ERROR,payload:response})
            // if(response.status === 401 || response.status === 403){
            //     ClearTokenRedirectLogout(action.external.navigate)
            // }
        }
    }
    catch(error){
        yield put({type:LOGOUT_ERROR,payload:error})
    }
}
function* logOutSaga(){
    yield takeLatest(LOGOUT,getLogOutApi)
}
export default logOutSaga;