import { all, fork } from "redux-saga/effects";
import singUpSaga from "./SignUp/saga";
import getCountriesSaga from "./Countries/saga";
import userSaga from "./user/saga";
import cartSaga from "./cart/saga";
import checkoutSaga from "./checkout/saga";
import signSaga from "./signIn/saga";
import paymentIntentSaga from "./paymentIntent/saga";
import logOutSaga from "./logOut/saga";
import orderHistorySaga from "./orderHistory/saga";
import productDetailsSaga from "./productDetails/saga";
import categoryDropdownSaga from "./categoryDropdown/saga";
import dropdownMasterSaga from "./dropdownMaster/saga";
import addFavouriteSaga from "./addFavourite/saga";
import bannersListSaga from "./withoutToken/bannersList/saga";
import riderProfiletSaga from "./withoutToken/riderProfile/saga";
import companyReviewsSaga from "./withoutToken/companyReviews/saga";
import anonymousSaga from "./anonymous/saga";
import orderDetailsSaga from "./orderDetails/saga";

const rootSaga = function* () {
    yield all([
        fork(singUpSaga),
        fork(getCountriesSaga),
        fork(checkoutSaga),
        fork(userSaga),
        fork(cartSaga),
        fork(signSaga),
        fork(paymentIntentSaga),
        fork(logOutSaga),
        fork(orderHistorySaga),
        fork(productDetailsSaga),
        fork(categoryDropdownSaga),
        fork(dropdownMasterSaga),
        fork(addFavouriteSaga),
        fork(bannersListSaga),
        fork(riderProfiletSaga),
        fork(companyReviewsSaga),
        fork(anonymousSaga),
        fork(orderDetailsSaga),
    ]);
};

export default rootSaga;