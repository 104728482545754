import React, { useEffect, useState } from "react";
import { Container, Row, Col, Stack, Button } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PerfectScooter from "components/common/perfectScooter/perfectScooter";
import "pages/Buy/Buy.scss";
import url from 'services/urls.json';
import { getMethod, getMethodWithToken, postMethodWithToken } from "services/apiServices";
import { useNavigate } from "react-router-dom";
import cartCallApi from "store/cart/action";
import { CART } from "store/cart/constant";
import { commonCallApi } from "store/common/action";
import { COMMON_DETAILS } from "store/common/constant";
import { useDispatch, useSelector } from "react-redux";
import userCallApi from "store/user/action";
import { USER } from "store/user/constant";
import { toast } from "react-toastify"
import { ClearTokenRedirectLogin } from "context/context";
import H1text from "components/H1text/H1text";
import Ptext from "components/Ptext/Ptext";
import { Box, Skeleton } from "@mui/material";
import styled from "styled-components";
import productDetailsCallApi from "store/productDetails/action";
import { PRODUCT_DETAILS } from "store/productDetails/constant";
import CommonModal from "components/Modal/CommonModal";
import InnerContent from "pages/ProductDetails/InnerContent";

import placeholderImage from 'assets/images/placeholderImage.png';
import { LazyImage } from "@kunalukey/react-image";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { CircularProgress } from "@mui/material";

const WrapperImageDiv = styled.div`
  background: url(${props => props.image});
  height: 346px;
  max-width: 346px;
  margin: auto;
  margin-top: 20px;
  cursor: pointer;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const VehicleShow = ({ productList, onClick, handleSelect, activeIndices, isModalActive, handleClose, selectedProduct, type, loader,addCartloader }) => {
  const [localActiveIndices, setLocalActiveIndices] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const initialActiveIndices = {};
    productList.forEach(product => {
      initialActiveIndices[product?.id] = 0;
    });
    setLocalActiveIndices(initialActiveIndices);
    // console.log("sdf",initialActiveIndices)
  }, [productList]);

  const handleCarouselSelect = (index, id) => {
    setLocalActiveIndices({
      ...localActiveIndices,
      [id]: index
    });
    handleSelect(index, id);
  };
console.log("asd",selectedProduct)
  const getProductDetails = (id) => {
    dispatch(productDetailsCallApi(PRODUCT_DETAILS, { id: id }, {
      run: true,
      handleFunction: () => {
        navigate(`/productdetails/${id}`, { state: { type: type } });
        window.scrollTo(0, 0);
      },
      toast: true,
      handleToast: (error) => { toast.error(error?.data?.detail); }
    }));
  };

  const productDetails = useSelector((state) => state?.productDetailsReducer?.payload?.data);
  // State to control visibility of the image once it's fully loaded
  const [isLoaded, setIsLoaded] = useState(false);  // State to track image load

  // Handle image load event
  const onImageLoad = () => {
    setIsLoaded(true);  // Set loaded state to true when the image is loaded
  };
  return (
    <>
      {!loader ?
        <>
          {productList && productList.length > 0 && productList.map((data,index) => (
            <Col lg={6} key={data?.id} className="d-flex">
              <Box 
              sx={{
                padding: {
                  xs: '4px 4px',
                  sm: '3px',
                  md: '16px',
                },
              }}
               className="h-100 w-100 d-flex flex-column" style={{ background: '#ffffff', borderRadius: '24px' }}>
                <div style={{ background: '#fafafa', borderRadius: '24px' }}>
                  <div className="p-0 p-md-3">
                    <div className="d-flex align-items-center gap-3 flex-wrap">
                      {data?.attributes.map((attribute, index) => {
                        if (attribute?.name === "Battery Capacity" || attribute?.name === "Charging Time" || attribute?.name === "Top Speed") {
                          return (
                            <Stack key={index} direction="horizontal" gap={1} style={{ fontWeight: '600' }} className="attributes">
                              <img src={attribute?.icon} alt={attribute?.name} className="w-4" style={{ marginRight: '4px' }} />
                              <Ptext size={'14'} lineHeight={'18'} fontWeight={'600'} marginTop={'0'} className="mb-0">
                                {attribute?.value}
                              </Ptext>
                            </Stack>
                          );
                        }
                        return null;
                      })}
                    </div>
                  </div>
                  <Box className="carouselBuy" sx={{
                              height: {
                                xs: 'auto',
                                sm: '478px !important',
                                md: '478px !important',
                                lg:'478px !important'
                              },
                            }}>
                    <Carousel
                      activeIndex={localActiveIndices[data?.id] || 0}
                      onSelect={(index) => handleCarouselSelect(index, data?.id)}
                      prevIcon={<div style={{ background: '#f6f6f6', padding: '36px 0', maxWidth: '24px', borderRadius: '12px' }}><ArrowBackIosIcon style={{ color: 'black', marginLeft: '7px' }} /></div>}
                      nextIcon={<div style={{ background: '#f6f6f6', padding: '36px 0', maxWidth: '24px', borderRadius: '12px' }}><ArrowForwardIosIcon style={{ color: 'black', marginRight: '7px' }} /></div>}
                      interval={null}
                    >
                      {data?.images && data?.images.map((item, index) => (
                        <Carousel.Item key={index} >
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Box sx={{
                              marginTop: {
                                xs: '0px',
                                sm: '10px',
                                md: '20px',
                              },
                              height: {
                                xs: '240px',
                                sm: '346px',
                                md: '346px',
                                lg:'346px'
                              },
                              // objectFit: 'cover',
                              // transform: 'scale(0.7)',
                              // transformOrigin: 'center'
                            }}
                            
                            style={{ maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => getProductDetails(data?.id)} >
                              {/* <WrapperImageDiv image={item?.original} onClick={() => getProductDetails(data?.id)} /> */}
                              <LazyLoadImage
                                alt="Description of image"
                                src={isLoaded ? item?.original : placeholderImage} // Show the placeholder image until the actual image is loaded
                                placeholderSrc={placeholderImage} // Optional: Placeholder image in case of lazy loading
                                // maxheight="240px"
                                // maxWidth="100%"
                                className={`mt-1 indicator-image ${localActiveIndices[data?.id] === index ? 'active' : ''}`}
                                style={{
                                  opacity: isLoaded ? 1 : 0, // Fade-in effect after loading the actual image
                                  transition: 'opacity 1s ease-in', // Smooth transition for the fade-in effect
                                  cursor: 'pointer',
                                  objectFit: 'cover',
                                }}
                                effect="blur" 
                                onLoad={onImageLoad} // Handle image load event
                              />

                            </Box>
                          </div>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                    <div className="d-none d-sm-flex justify-content-center mt-3 flex-wrap">
                      {data?.images?.length > 0 && data?.images.map((image, index) => (
                        <div key={index} onClick={() => handleCarouselSelect(index, data?.id)}>

                          <LazyLoadImage
                            alt="Description of image"
                            src={image?.original} // The actual image URL
                            placeholderSrc={placeholderImage} // Placeholder image URL
                            // height="auto"
                            className={`mt-1 indicator-image ${localActiveIndices[data?.id] === index ? 'active' : ''}`}
                            style={{ cursor: 'pointer', width: '64px', height: '64px', objectFit: 'cover', margin: '0 5px', border: localActiveIndices[data?.id] === index ? '2px solid #4579F5' : 'none', borderRadius: '15%' }}
                          // effect="blur" // Optional: adds a blur effect during loading
                          />
                          
                        </div>
                        // <img
                        //   key={index}
                        //   src={image?.original}
                        //   alt={`Indicator ${index}`}
                        //   className={`mt-1 indicator-image ${localActiveIndices[data?.id] === index ? 'active' : ''}`}
                        //   onClick={() => handleCarouselSelect(index, data?.id)}
                        //   style={{ cursor: 'pointer', width: '64px', height: '64px', objectFit: 'cover', margin: '0 5px', border: localActiveIndices[data?.id] === index ? '2px solid #4579F5' : 'none', borderRadius: '15%' }}
                        // />
                      ))}
                    </div>
                  </Box>
                  <Box sx={{
                              padding: {
                                xs: '0',
                                sm: '24px',
                                md: '24px',
                                lg:'24px'
                              },
                            }}>
                  <Row style={{ backgroundColor: "#fafafa", borderRadius: "12px", margin: '0px', marginTop: "16px" }}>
                    <Col md={4} lg={3} className={'p-0'}>
                      <Ptext size="18" lineHeight={'28'} fontWeight={'600'} marginTop="0">{data?.title}</Ptext>
                      <Ptext size="20" lineHeight={'28'} fontWeight={'700'} marginTop="0">${data?.phone_mount}</Ptext>
                    </Col>
                    <Col md={8} lg={9} className="d-flex p-0 justify-content-start justify-content-md-end mt-2 mt-md-0 align-items-center">
                      <Button style={{ marginRight: '16px', height: '48px' }} variant="outline-dark" className="fw-bold CartButton" onClick={() => onClick(data)}>
                        <Ptext size="16" lineHeight={'24'} fontWeight={'600'} marginTop={'0'}>
                        {(selectedProduct?.id==data?.id && addCartloader)?<CircularProgress style={{ 'color': '#808080', 'width': '25px', 'height': '25px' }} />:
                          <>Buy&nbsp;/&nbsp;{data?.price_incl_tax}</>}
                          </Ptext>
                      </Button>
                      <Button variant="dark" className="fw-bold rentButton" style={{ height: '48px' }}>
                        <Ptext size="16" lineHeight={'24'} fontWeight={'600'} marginTop={'0'}>Rent&nbsp;/&nbsp;{data?.rent}</Ptext>
                      </Button>
                    </Col>
                  </Row>
                  </Box>
                </div>
              </Box>
            </Col>
          ))}
          <CommonModal
            padding={'32px 40px'}
            width={'736px'}
            modalActive={isModalActive}
            handleClose={handleClose}
            innerContent={
              <InnerContent productDetails={selectedProduct} handleClose={handleClose} type={type} />
            }
          />
        </>
        :
        <>
          {[1, 2].map((_, index) => (
            <Col lg={6} key={index} className="d-flex g-3">
              <Box sx={{
                padding: {
                  xs: '3px',
                  sm: '3px',
                  md: '16px',
                },
              }} className="h-100 w-100 d-flex flex-column" style={{ background: '#ffffff', borderRadius: '24px'}}>
                <Skeleton
                sx={{
                  height: {
                    xs: '240px',
                    sm: '450px',
                    md: '450px',
                    lg:'450px'
                  },
                }}
                 variant="rectangular" width="100%" style={{ borderRadius: '24px' }} />
                <Box mt={2} display='flex' justifyContent='center' gap={3}>
                  <Skeleton variant="text" width={'64px'} height={'100px'} />
                  <Skeleton variant="text" width={'64px'} height={'100px'} />
                  <Skeleton variant="text" width={'64px'} height={'100px'} />
                  <Skeleton variant="text" width={'64px'} height={'100px'} />
                </Box>
                <Box mt={2} className="d-flex justify-content-between">
                  <Skeleton variant="rectangular" width="45%" height={36} />
                  <Skeleton variant="rectangular" width="45%" height={36} />
                </Box>
              </Box>
            </Col>
          ))}
        </>
      }
    </>
  );
};

export default VehicleShow;
