import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, FloatingLabel, Button, Stack, } from "react-bootstrap";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import 'pages/Compare/Compare.scss';
import url from 'services/urls.json';
import { getMethod, getMethodWithToken, postMethod, postMethodWithToken } from "services/apiServices";
import { CleaningServices } from "@mui/icons-material";
import { ClearTokenRedirectLogin } from "context/context";
import { useNavigate } from "react-router-dom";
import H1text from "components/H1text/H1text";
import PlaceholderInput from "components/Inputs/PlaceholderInput/PlaceholderInput";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Rating from "components/Rating/Rating";
import Ptext from "components/Ptext/Ptext";
import userCallApi from "store/user/action";
import { USER } from "store/user/constant";
import cartCallApi from "store/cart/action";
import { CART } from "store/cart/constant";
import { toast } from "react-toastify"
import { commonCallApi } from "store/common/action";
import { COMMON_DETAILS } from "store/common/constant";
import CommonModal from "components/Modal/CommonModal";
import InnerContent from "pages/ProductDetails/InnerContent";
import productDetailsCallApi from "store/productDetails/action";
import { PRODUCT_DETAILS } from "store/productDetails/constant";
import addFavouriteCallApi from "store/addFavourite/action";
import { ADD_FAVOURITE } from "store/addFavourite/constant";
import favoriteSvg from 'assets/svg/favorite-black.svg';
import { CircularProgress, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import placeholderImage from 'assets/images/placeholderImage.png';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { motion } from "framer-motion"; 
import { containerVariants, getToken } from "services/common";

// import { CircularProgress } from "@mui/material";
const WrapperImageDiv = styled.div`
  background: url(${props => props.image});
  height: 346px;
  max-width:346px;
  margin:auto;
  marginTop: 20px;
  cursor:pointer;
  background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height:220px;

    @media screen and (max-width:450px){
        height:94px;
    }
`;

const Compare = () => {
    const navigate = useNavigate()

    const [options, setOptions] = useState([]);
    const [firstId, setFirstId] = useState("");
    const [secondId, setSecondId] = useState("");
    const [products, setProducts] = useState([]);
    const [features, setFeatures] = useState([]);
    const dispatch = useDispatch();
    const commonId = useSelector((state) => state?.commonReducer?.id);
    const [loader, setLoader] = useState(false);
    const [favouriteSelected, setFavouriteSelected] = useState(false);
    const [loaderItemOne, setLoaderItemOne] = useState(false);
    const [loaderItemTwo, setLoaderItemTwo] = useState(false);
    const [isModalActive, setIsModalActive] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [addCartloader, setAddCartloader] = useState(false);
    const [favouriteSelectedTwo, setFavouriteSelectedTwo] = useState(false);

    const handleDropdown = async () => {
        let apiUrl = `${url.buy}?catagory=scooter&paginate=false&page_limit=0`;
        let response = localStorage.getItem("token") ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl)
        if (response.status === 200 || response.status === 201) {
            setOptions(response.data)
        }
        else if (response.status === 401 || response.status === 403) {
            ClearTokenRedirectLogin(navigate)
        }
    }

    useEffect(() => {
        setLoader(true)
    }, []);

    const handleCompare = async (type = "") => {
        if (type == 'showShimmer') {

        } else {
            setLoader(true);
        }

        if (firstId === '' && secondId === '') {
            let apiUrl = url.compare;
            let response = localStorage.getItem("token") ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl)
            if (response.status === 200 || response.status === 201) {
                
                // setLoader(false)
                if (commonId?.id) {
                    setFirstId(parseInt(commonId?.id))
                } else {
                    setFirstId(parseInt(response?.data?.[0]?.id));
                }
                setSecondId(response.data[1].id);
                response?.data.map((data, index) => {
                    setFeatures(data?.attributes)

                })
                setProducts(response.data)
            }
            else if (response.status === 401 || response.status === 403) {
                setLoader(false)
                ClearTokenRedirectLogin(navigate)
            }
        }
        else {
            let apiUrl = `${url.compare}?product_first=${firstId}&product_second=${secondId}`
            let response = localStorage.getItem("token") ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl)
            if (response.status === 200 || response.status === 201) {
               
                setLoader(false)
                response?.data.map((data, index) => {
                    setFeatures(data?.attributes)
                })
                setProducts(response.data)
            }
            else if (response.status === 401 || response.status === 403) {
                ClearTokenRedirectLogin(navigate)
                setLoader(false)
            }
        }
    }

    const handleFirstProduct = (e) => {
        setFirstId(e.target.value)
    }

    const handleSecondProduct = (e) => {
        setSecondId(e.target.value);
    }

    useEffect(() => {
        handleDropdown()
    }, []);

    useEffect(() => {
        handleCompare();
    }, [firstId, secondId])

    const [formValue, setFormvalue] = useState({
        "scooter1": "",
        "scooter2": "",
    });
    const handleChange = (e) => {
        if (e.target.name == "scooter1") {
            setFirstId(e.target.value);
        }
        if (e.target.name == "scooter2") {
            setSecondId(e.target.value);
        }

    };
  
    const handleClose = () => setIsModalActive(false);
    const handleAddCart = async (product) => {
        setAddCartloader(true);
        setSelectedProduct(product);
        let body = {
            "url": product.url,
            "quantity": 1
        }

        let response = localStorage.getItem("token") ? await postMethodWithToken(url.addCart, body) : await postMethod(url.addCart, body)
            if (response.status === 200 || response.status === 201) {
                const basket = response.data.lines.split('/api/v1/')[1]
                toast.success("Item Added to cart")
                localStorage.setItem("basketId",response?.data?.id);
                setIsModalActive(true);
                if(getToken()){
                    dispatch(userCallApi(USER, {}, {
                        run: true,
                        handleFunction: (res) => {handleCartCount(res?.data?.basket_id);}
                    }))
                }

                localStorage.setItem('cartAPI', basket)
                localStorage.setItem("clearAllCart", response.data.url);
                setAddCartloader(false);
            }
            // else if (response.status === 401) {
            //     navigate('/signin');
            //     setAddCartloader(false);
            // }
            else if (response.status === 406) {
                toast.error("Out Of Stock");
                setAddCartloader(false);
            }
        // })
    }

    const handleCartCount = (basketId) => {
        dispatch(cartCallApi(CART, {}, {
            url: `ecom/baskets/${basketId}/lines`,
            run: true,
            handleFunction: (res) => handleSuccess(res)
        }))
    };

    const handleSuccess = (res) => {
        dispatch(commonCallApi(COMMON_DETAILS, { cart: res?.data?.[0]?.total_quantity }, { run: true }))
    };

    const getProductDetails = (id) => {
        dispatch(productDetailsCallApi(PRODUCT_DETAILS, { id: id }, {
            run: true,
            handleFunction: () => { navigate(`/productdetails/${id}`, { state: { type: 'scooter' } }); window.scrollTo(0, 0); },
            toast: true,
            handleToast: (error) => { toast.error("Session expired. Please log in again.") }
        }));
    }

    const getfavoriteProductDetails = (id) => {
        dispatch(productDetailsCallApi(PRODUCT_DETAILS, { id: id }, {
            run: true,
            handleFunction: () => { },
            toast: true,
            handleToast: (error) => { toast.error("Session expired. Please log in again.") }
        }));
    }

   
    const addFavourite = (data) => {
        setLoaderItemOne(true)
        let body = {
            "productId": data?.id,
            "is_favourite": !data?.is_favorite
        }
        dispatch(addFavouriteCallApi(ADD_FAVOURITE, body, {
            run: true,
            handleFunction: (res) => { handleCompare('showShimmer'); setLoaderItemOne(false); setFavouriteSelected(res?.data?.is_favorite); !res?.data?.is_favorite ? toast.success(res?.data?.message) : toast.error(res?.data?.message); }
        }))
    };

    const addFavouriteTwo = (data) => {
        setLoaderItemTwo(true)
        let body = {
            "productId": data?.id,
            "is_favourite": !data?.is_favorite
        }
        dispatch(addFavouriteCallApi(ADD_FAVOURITE, body, {
            run: true,
            handleFunction: (res) => { handleCompare('showShimmer'); setLoaderItemTwo(false); setFavouriteSelectedTwo(res?.data?.is_favorite); !res?.data?.is_favorite ? toast.success(res?.data?.message) : toast.error(res?.data?.message); }
        }))
    };
    return (
        <motion.div
        variants={containerVariants}
            initial="hidden"
            animate="visible"
         >
            
        <Box
            sx={{
                paddingBottom: {
                    xs: '12px',
                    sm: '24px',
                    md: '48px',
                    lg: '96px'
                },
                paddingTop: {
                    xs: '6px',
                    sm: '13px',
                    md: '26px',
                    lg: '58px'
                }
            }}
            style={{ fontFamily: "Manrope, sans-serif", background: '#fafafa' }} className="compare">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={4}>
                        <H1text size={'36'} lineHeight={'36'} fontWeight={600} marginTop='0'>Compare</H1text>
                    </Col>
                    <Col xs={6} md={4}>
                        <div className="form_box d-flex gap-2 justify-content-md-end justify-content-start mt-2 mt-md-0">
                            <PlaceholderInput
                                label={'Scooter #1'}
                                placeholder={'Scooter #1'}
                                data={options?.length > 0 ? options.filter(item => item.id !== secondId) : []}
                                value={firstId}
                                name={'scooter1'}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                    </Col>
                    <Col xs={6} md={4}>
                        <div className="form_box d-flex gap-2 justify-content-md-end justify-content-start mt-2  mt-md-0">
                            <PlaceholderInput
                                label={'Scooter #2'}
                                placeholder={'Scooter #2'}
                                data={options?.length > 0 ? options.filter(item => item.id !== firstId) : []}
                                value={secondId}
                                name={'scooter2'}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <Box
                    sx={{
                        marginTop: {
                            xs: '5px',
                            sm: '10px',
                            md: '21px',
                            lg: '42px'
                        },
                    }}
                >
                    <Row className=""  >
                        {!loader ?
                            <>
                                {products && products?.length > 0 && products.map((data, index) => (
                                    index == 0 &&
                                    <Col xs={6} lg={4} key={index} className="d-none d-lg-flex">
                                        <div style={{ background: '#fafafa', padding: '16px', borderRadius: '24px', width: '100%' }}>
                                            <div style={{ visibility: 'hidden' }}>
                                                <Stack direction="horizontal" gap={3}>
                                                    <div className="d-flex justify-content-between align-items-center gap-1 "><span className="fw-bold " style={{ background: '#fafafa', borderRadius: '28px', padding: '4px 12px' }}>4/5</span></div>
                                                    <div className="p-2 ms-auto d-flex gap-2 fw-bold"><div ><div >
                                                        {!data?.is_favorite ? <FavoriteBorderIcon style={{ width: '20px', height: '20px', cursor: 'pointer' }} /> : <img style={{ width: '20px' }} src={favoriteSvg} alt="" />}</div> <div>
                                                        </div></div> <div><ShoppingCartOutlinedIcon style={{ width: '20px', height: '20px' }} /></div></div>
                                                </Stack>
                                                <H1text size='30' lineHeight='36' fontWeight='600' className="fw-bold text-black mt-2 mb-4">{data?.title}</H1text>
                                                <div >
                                                    {/* <img src={data?.images[0]?.original} className="mx-auto" style={{height:'300px'}} /> */}
                                                    <WrapperImageDiv image={data?.images[0]?.original} >

                                                    </WrapperImageDiv>
                                                </div>

                                            </div>
                                            <div style={{ background: '#ffffff', borderRadius: '20px', padding: '24px 16px 16px 16px' }} className=" px-3 pt-3 pb-3 mt-3 mb-2">
                                                {data?.attributes && data?.attributes.map((item, index) => (
                                                    <>
                                                        <Row key={index} gap={2} style={{ maxHeight: '40px', minHeight: '40px' }} className="flex-wrap pb-3 justify-content-between minMaxXs">
                                                            <Col xs={12} className="d-flex  align-items-center">
                                                                <img src={item?.icon} style={{ width: '15px', height: '15px', color: '#c5c5c5', marginRight: '12px' }} />
                                                                <Ptext size='14' lineHeight='24' fontWeight='700' marginTop="0">
                                                                    {item?.name}
                                                                </Ptext>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </>
                            :
                            <>
                                <Col xs={6} lg={4} className="d-none d-lg-flex">
                                    {[1].map((_, index) => (
                                        <Col lg={12} key={index} className="d-flex g-3" style={{ marginTop: '354px' }}>
                                            <div className="h-100 w-100 d-flex flex-column" style={{ background: '#ffffff', borderRadius: '24px', padding: '16px' }}>
                                                <Box >
                                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((_, index) => (
                                                        <Box mt={2} display='flex' justifyContent='start' gap={3} >
                                                            <Skeleton variant="text" width={'40%'} height={'20px'} />
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </div>
                                        </Col>
                                    ))}
                                </Col>
                            </>
                        }


                        {!loader ?
                            <>
                                {products && products?.length > 0 && products.map((data, index) => (
                                    data?.id == firstId &&
                                    <Col key={index} xs={6} lg={4} className={'removePaddingXs'}>
                                        <div className={'removePaddingXs'} style={{ background: '#ffffff', padding: '16px', borderRadius: '24px' }}>
                                            <div>
                                                <Stack direction="horizontal" gap={3}>
                                                    <div className="d-flex justify-content-between align-items-center gap-1 "><span className="fw-bold " style={{ background: '#fafafa', borderRadius: '28px', padding: '4px 12px' }}>4/5</span></div>
                                                    <div className="p-2 ms-auto d-flex gap-2 fw-bold">
                                                        <div className=" d-flex gap-2 cursor-pointer" onClick={() => addFavourite(data)}>
                                                            {loaderItemOne ?
                                                                // <img style={{ width: '20px' }} src={favoriteSvg} alt="" />
                                                                <CircularProgress style={{ width: '20px', height: '20px', cursor: 'pointer' }} />
                                                                :
                                                                <>
                                                                    {!data?.is_favorite ? <FavoriteBorderIcon style={{ width: '20px', height: '20px', cursor: 'pointer' }} /> : <img style={{ width: '20px' }} src={favoriteSvg} alt="" />}
                                                                </>
                                                            }
                                                        </div> <div>
                                                        </div>
                                                        {selectedProduct?.id==data?.id && addCartloader ?
                                                            <CircularProgress style={{ width: '20px', height: '20px', cursor: 'pointer' }} /> :
                                                            <ShoppingCartOutlinedIcon className="cursor-pointer " style={{ width: '20px', height: '20px' }} onClick={() => { handleAddCart(data); }} />
                                                        }

                                                    </div>
                                                </Stack>
                                                <h1 className="responsiveText text-black mt-2 mb-4">{data?.title}</h1>
                                                <div >
                                                    {/* <img src={data?.images[0]?.original} className="mx-auto" style={{height:'300px'}} /> */}
                                                    {/* <WrapperImageDiv image={data?.images[0]?.original} onClick={() => getProductDetails(data?.id)}>

                                                    </WrapperImageDiv> */}
                                                    <Box display="flex" justifyContent={'center'}>
                                                        <Box
                                                            height="220px"
                                                            maxHeight="220px"
                                                            maxWidth="220px"
                                                            display="contents"
                                                            margin="auto"
                                                        >

                                                            {/* <WrapperImageDiv image={data?.images[0]?.original} onClick={() => getProductDetails(data?.id)}>

                                                </WrapperImageDiv> */}
                                                            <LazyLoadImage
                                                                alt="Description of image"
                                                                src={data?.images[0]?.original}
                                                                placeholderSrc={placeholderImage}
                                                                onClick={() => getProductDetails(data?.id)}
                                                                style={{
                                                                    height: ' 220px',
                                                                    width: '100%',
                                                                    margin: 'auto',
                                                                    cursor: 'pointer',
                                                                }}
                                                                effect="blur"
                                                            />
                                                        </Box>
                                                    </Box>
                                                </div>

                                            </div>
                                            <div style={{ background: '#f8f9fa', borderRadius: '20px', padding: '24px 16px 16px 16px' }} className="removePaddingXs px-3 pt-3 pb-3 mt-3 mb-2">
                                                {data?.attributes && data?.attributes.map((item, index) => (
                                                    <>
                                                        <div key={index} className=" d-flex d-lg-none align-items-center gap-2 mt-4 mt-lg-0 mb-1 mb-lg-0" style={{ background: '#e1e1e1', borderRadius: '8px', padding: '0 6px' }} >
                                                            <img src={item?.icon} style={{ width: '15px', height: '15px', color: '#c5c5c5' }} />
                                                            <Ptext size='14' lineHeight='24' fontWeight='700' marginTop="0"
                                                                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item?.name}</Ptext>
                                                        </div>
                                                        <Row gap={2} className="flex-wrap pb-3 justify-content-between minMaxXs" style={{ maxHeight: '40px', minHeight: '40px' }} >
                                                            <Col xs={12} lg={6} className="">
                                                                <Ptext size='14' lineHeight='24' fontWeight='700' marginTop="0"
                                                                    style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                >
                                                                    {item?.value}
                                                                </Ptext>
                                                            </Col>
                                                            <Col xs={12} lg={6} className="mt-2 mt-lg-0 d-flex justify-content-start justify-content-lg-end">
                                                                <Rating rating={4} />
                                                            </Col>
                                                        </Row>
                                                    </>
                                                ))}
                                            </div>
                                            <Stack direction="vertical" gap={3}>
                                                <Button variant="outline-dark p-2 buyButton " onClick={() => { handleAddCart(data);  }}> Buy /  ${data?.price_incl_tax}</Button>
                                                <Button className="bg-dark rentButton buyButton p-2">Rent / ${data?.rent}</Button>
                                            </Stack>
                                        </div>
                                    </Col>
                                ))}
                            </>
                            :
                            <>
                                <Col xs={6} lg={4} >
                                    {[1].map((_, index) => (
                                        <Col lg={12} key={index} className="d-flex g-3">
                                            <div className="h-100 w-100 d-flex flex-column" style={{ background: '#ffffff', borderRadius: '24px', padding: '16px' }}>
                                                <div >
                                                    <Stack direction="horizontal" gap={3}>
                                                        <div className="d-flex justify-content-between align-items-center gap-1 "><span className="fw-bold" style={{ background: '#fafafa', borderRadius: '28px', padding: '4px 12px' }}>&nbsp; &nbsp; &nbsp; &nbsp;</span></div>
                                                        <div className="p-2 ms-auto d-flex gap-2 fw-bold">
                                                            <div className=" d-flex gap-2 cursor-pointer" >
                                                                <FavoriteBorderIcon style={{ width: '20px', height: '20px', cursor: 'pointer' }} />
                                                            </div>
                                                            <ShoppingCartOutlinedIcon className="cursor-pointer ms-2" style={{ width: '20px', height: '20px' }} />

                                                        </div>
                                                    </Stack>
                                                    <h1 className="responsiveText text-black mt-2 mb-4">  <Skeleton variant="text" width={'75%'} height={'36px'} /></h1>

                                                </div>
                                                <Skeleton variant="rectangular" width="100%" height={'220px'} style={{ borderRadius: '24px' }} />
                                                <Box marginTop={'16px'}>
                                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((_, index) => (
                                                        <Box mt={2} display='flex' justifyContent='space-between' gap={3} >
                                                            <Skeleton variant="text" width={'30%'} height={'20px'} />
                                                            <Skeleton variant="text" width={'40%'} height={'20px'} />
                                                        </Box>
                                                    ))}
                                                </Box>
                                                <Box mt={2} className="d-flex justify-content-between" marginTop={'28px'}>
                                                    <Skeleton variant="rectangular" width="100%" height={36} />
                                                </Box>
                                                <Box mt={2} className="d-flex justify-content-between">
                                                    <Skeleton variant="rectangular" width="100%" height={36} />
                                                </Box>
                                            </div>
                                        </Col>
                                    ))}
                                </Col>
                            </>
                        }


                        {!loader ?
                            <>
                                {products && products.length > 0 && products.map((data, index) => (
                                    data?.id == secondId &&
                                    <Col key={index} xs={6} lg={4} className={'removePaddingXs'} >
                                        <div className={'removePaddingXs'} style={{ background: '#ffffff', padding: '16px', borderRadius: '24px' }}>
                                            <div >
                                                <Stack direction="horizontal" gap={3}>
                                                    <div className="d-flex justify-content-between align-items-center gap-1 "><span className="fw-bold" style={{ background: '#fafafa', borderRadius: '28px', padding: '4px 12px' }}>4/5</span></div>
                                                    <div className="p-2 ms-auto d-flex gap-2 fw-bold">
                                                        <div className=" d-flex gap-2 cursor-pointer" onClick={() => addFavouriteTwo(data)}>

                                                            {loaderItemTwo ?
                                                                // <img style={{ width: '20px' }} src={favoriteSvg} alt="" />
                                                                <CircularProgress style={{ width: '20px', height: '20px', cursor: 'pointer' }} />
                                                                :
                                                                <>
                                                                    {!data?.is_favorite ? <FavoriteBorderIcon style={{ width: '20px', height: '20px', cursor: 'pointer' }} /> : <img style={{ width: '20px' }} src={favoriteSvg} alt="" />}
                                                                </>
                                                            }
                                                        </div> <div>
                                                        </div>
                                                        {selectedProduct?.id==data?.id && addCartloader ?
                                                            <CircularProgress style={{ width: '20px', height: '20px', cursor: 'pointer' }} /> :
                                                            <ShoppingCartOutlinedIcon className="cursor-pointer " style={{ width: '20px', height: '20px' }} onClick={() => { handleAddCart(data); }} />
                                                        }

                                                    </div>
                                                </Stack>
                                                <h1 className="responsiveText text-black mt-2 mb-4">{data?.title}</h1>
                                                <Box display="flex" justifyContent={'center'}>
                                                    <Box
                                                        height="220px"
                                                        maxHeight="220px"
                                                        maxWidth="220px"
                                                        display="contents"
                                                        margin="auto"
                                                    >

                                                        {/* <WrapperImageDiv image={data?.images[0]?.original} onClick={() => getProductDetails(data?.id)}>

                                                </WrapperImageDiv> */}
                                                        <LazyLoadImage
                                                            alt="Description of image"
                                                            src={data?.images[0]?.original}
                                                            placeholderSrc={placeholderImage}
                                                            onClick={() => getProductDetails(data?.id)}
                                                            style={{
                                                                height: ' 220px',
                                                                width: '100%',
                                                                margin: 'auto',
                                                                cursor: 'pointer',
                                                            }}
                                                            effect="blur"
                                                        />
                                                    </Box>
                                                </Box>

                                            </div>
                                            <div style={{ background: '#f8f9fa', borderRadius: '20px', padding: '24px 16px 16px 16px' }} className="removePaddingXs px-3 pt-3 pb-3 mt-3 mb-2">
                                                {data?.attributes && data?.attributes.map((item, index) => (
                                                    <>
                                                        <div key={index} className=" d-flex d-lg-none align-items-center gap-2 mt-4 mt-lg-0 mb-1 mb-lg-0" style={{ background: '#e1e1e1', borderRadius: '8px', padding: '0 6px' }} >
                                                            <img src={item?.icon} style={{ width: '15px', height: '15px', color: '#c5c5c5' }} />
                                                            <Ptext size='14' lineHeight='24' fontWeight='700' marginTop="0"
                                                                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                            >{item?.name}</Ptext>
                                                        </div>
                                                        <Row gap={2} className="flex-wrap pb-3 justify-content-between minMaxXs" style={{ maxHeight: '40px', minHeight: '40px' }}>
                                                            <Col xs={12} lg={6} className="">
                                                                <Ptext size='14' lineHeight='24' fontWeight='700' marginTop="0"
                                                                    style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                                >
                                                                    {item?.value}
                                                                </Ptext>
                                                            </Col>
                                                            <Col xs={12} lg={6} className="mt-2 mt-lg-0 d-flex justify-content-start justify-content-lg-end">
                                                                <Rating rating={4} />
                                                            </Col>
                                                        </Row>
                                                    </>
                                                ))}
                                            </div>
                                            <Stack direction="vertical" gap={3}>
                                                <Button variant="outline-dark p-2 buyButton " onClick={() => { handleAddCart(data); }}> Buy /  ${data?.price_incl_tax}</Button>
                                                <Button className="bg-dark rentButton buyButton p-2">Rent / ${data?.rent}</Button>
                                            </Stack>

                                        </div>
                                    </Col>
                                ))}
                            </>
                            :
                            <>
                                <Col xs={6} lg={4} >
                                    {[1].map((_, index) => (
                                        <Col lg={12} key={index} className="d-flex g-3">
                                            <div className="h-100 w-100 d-flex flex-column" style={{ background: '#ffffff', borderRadius: '24px', padding: '16px' }}>
                                                <div >
                                                    <Stack direction="horizontal" gap={3}>
                                                        <div className="d-flex justify-content-between align-items-center gap-1 "><span className="fw-bold" style={{ background: '#fafafa', borderRadius: '28px', padding: '4px 12px' }}>&nbsp; &nbsp; &nbsp; &nbsp;</span></div>
                                                        <div className="p-2 ms-auto d-flex gap-2 fw-bold">
                                                            <div className=" d-flex gap-2 cursor-pointer" >
                                                                <FavoriteBorderIcon style={{ width: '20px', height: '20px', cursor: 'pointer' }} />
                                                            </div>
                                                            <ShoppingCartOutlinedIcon className="cursor-pointer ms-2" style={{ width: '20px', height: '20px' }} />

                                                        </div>
                                                    </Stack>
                                                    <h1 className="responsiveText text-black mt-2 mb-4">  <Skeleton variant="text" width={'75%'} height={'36px'} /></h1>

                                                </div>
                                                <Skeleton variant="rectangular" width="100%" height={'220px'} style={{ borderRadius: '24px' }} />
                                                <Box marginTop={'16px'}>
                                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((_, index) => (
                                                        <Box mt={2} display='flex' justifyContent='space-between' gap={3} >
                                                            <Skeleton variant="text" width={'30%'} height={'20px'} />
                                                            <Skeleton variant="text" width={'40%'} height={'20px'} />
                                                        </Box>
                                                    ))}
                                                </Box>
                                                <Box mt={2} className="d-flex justify-content-between" marginTop={'28px'}>
                                                    <Skeleton variant="rectangular" width="100%" height={36} />
                                                </Box>
                                                <Box mt={2} className="d-flex justify-content-between">
                                                    <Skeleton variant="rectangular" width="100%" height={36} />
                                                </Box>
                                            </div>
                                        </Col>
                                    ))}
                                </Col>
                            </>
                        }



                    </Row>
                </Box>
                <CommonModal
                    padding={'32px 40px'}
                    width={'736px'}
                    modalActive={isModalActive}
                    handleClose={handleClose}
                    innerContent={
                        <InnerContent productDetails={selectedProduct} handleClose={handleClose} />
                    }
                />
            </Container>
        </Box>
        </motion.div>
    )
}

export default Compare