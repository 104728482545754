import { takeLatest,put } from "redux-saga/effects";
import { getMethod, getMethodWithToken } from "services/apiServices";
import url from 'services/urls.json';
import { PRODUCT_DETAILS , PRODUCT_DETAILS_ERROR,PRODUCT_DETAILS_SUCCESS } from "./constant";
import { ClearTokenRedirectLogin } from "context/context";

function* productDetailsApi(action){
    try {
         // Destructure `id` from the action payload
         const { id } = action.data;

         // Construct the URL with the dynamic id
         const urlWithId = `${url.getProduct}/${id}`;
        const response=yield getMethod(urlWithId)
            if(response.status === 200 || response.status === 201){
                yield put({type:PRODUCT_DETAILS_SUCCESS , payload:response})
                if(action.external && action.external.run === true){
                    action.external.handleFunction(response)
                }
                if(action.external && action.external.toast === true){
                    // action.external.handleToast(response)
                }
            }
            else {
                yield put({type:PRODUCT_DETAILS_ERROR , payload:response})
                if(response.status === 401 && action.external && action.external.toast === true){
                    action.external.handleToast("Session expired. Please log in again.")
                  }
                if(response.status === 401 || response.status === 403){
                    ClearTokenRedirectLogin(action.external.navigate)
                  }

                if(action.external && action.external.toast === true){
                    action.external.handleToast(response)
                }
                
            }
    }
    catch(error){
        yield put({type:PRODUCT_DETAILS_ERROR , payload:error})
    }
}

function* productDetailsSaga(){
    yield takeLatest(PRODUCT_DETAILS , productDetailsApi)
}

export default productDetailsSaga