import { React, useState, useEffect, lazy, Suspense } from "react";
import { Container, Row, Col, Button, Stack } from "react-bootstrap";
import PerfectScooter from "components/common/perfectScooter/perfectScooter";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Carousel from 'react-bootstrap/Carousel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PublicTransport from "./PublicTransport/PublicTransport";
import ModerApp from "./ModerApp/ModerApp";
import Reviews from "./Reviews/Reviews";
import "pages/Home/Home.scss";
import { getMethodWithToken, getMethod, postMethodWithToken, postMethod } from "services/apiServices";
import url from 'services/urls.json';
import { useDispatch, useSelector } from "react-redux";
import { ClearTokenRedirectLogin } from "context/context";
import { useNavigate } from "react-router-dom";
import ErrorBoundary from "routes/errorBoundary";
import { Box, Skeleton } from "@mui/material";
import H1text from "components/H1text/H1text";
import Ptext from "components/Ptext/Ptext";
import styled from "styled-components";
import CardImage2 from "assets/images/CardImage2.png";
import vehicle from "assets/images/vehicle.png";
import firstScreen from "assets/heroBanner/firstScreen.png";
import firstScreen2 from "assets/heroBanner/firstScreen2.png";
import firstScreen3 from "assets/heroBanner/firstScreen3.png";
import firstScreen4 from "assets/heroBanner/firstScreen4.png";
import userCallApi from "store/user/action";
import cartCallApi from "store/cart/action";
import { toast } from "react-toastify"
import { CART } from "store/cart/constant";
import { USER } from "store/user/constant";
import { commonCallApi } from "store/common/action";
import { COMMON_DETAILS } from "store/common/constant";
import CommonModal from "components/Modal/CommonModal";
import InnerContent from "pages/ProductDetails/InnerContent";
import placeholderImage from 'assets/images/placeholderImage.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Rating from "components/Rating/Rating";
import { CircularProgress } from "@mui/material";
import { motion } from "framer-motion";
import { containerVariants, getToken } from "services/common";
import bannersListCallApi from "store/withoutToken/bannersList/action";
import { BANNERS_LIST } from "store/withoutToken/bannersList/constant";

const WrapperDiv = styled.div`
.firstImage{
    background:url('https://metrorides.theclientdemos.com/assets/images/i6.png');
    height:504px;
    width:100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius:16px;

}
.thirdImage{
    background:url('https://metrorides.theclientdemos.com/assets/images/i7.png');
    height:244px;
    width:100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius:16px;
}
.secondImage{
    background-image: url(${props => props.image});
    height:244px;
    width:100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius:16px;
}
.textWidth{
    width:75%;
}
@media screen and (max-width:1200px){
    .textWidth{
        width:100%;
    }
}

`
const WrapperImageDiv = styled.div`
  background: url(${props => props.image});
  height: 346px;
  max-width:346px;
  margin:auto;
  marginTop: 20px;
  background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
`;
const Home = () => {
    const Questions = lazy(() => import("./Questions/Questions"));

    const array = new Array(3).fill(null);
    const navigate = useNavigate()
    const [selectedId, setSelectedId] = useState(0);
    const [selectedSingleProduct, setSelectedSingleProduct] = useState({});
    const [activeIndex, setActiveIndex] = useState(0);
    const bannerList = useSelector((state) => state?.bannersListReducer?.payload?.data);
    const bannerListLoader = useSelector((state) => state?.bannersListReducer?.loading);

    const handleNavProducts = (item) => {
        setSelectedId(item?.id);
        setSelectedSingleProduct(item);
        setActiveIndex(0);
    };

    const [id, setId] = useState('')
    const [order, setOrder] = useState(0)
    const [productList, setProductList] = useState([])
    const [productId, setProductId] = useState('')

    const handleSelect = (index) => {
        console.log("axc", index)
        setTimeout(() => {
            setActiveIndex(index);
        }, 50);
        // console.log("axc",selectedSingleProduct?.images)
    };

    useEffect(() => {
        handleProductList()
        // setActiveIndex(0);
    }, [])

    const [loader, setLoader] = useState(false)

    const handleProductList = async () => {
        setLoader(true)
        let apiUrl = `${url.buy}?catagory=scooter&paginate=false&page_limit=0`;
        let response = localStorage.getItem('token') ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl)
        if (response.status === 200 || response.status === 201) {
            console.log("cvbcvb", response.data)
            setProductList(response.data);
            setSelectedSingleProduct(response.data[0]);
            setLoader(false)
        }
        else if (response.status === 401 || response.status === 403) {
            ClearTokenRedirectLogin(navigate)
            setLoader(false)
        }
    }
    useEffect(() => {
        getBannerList();
        // getRiderProfile();
        window.scrollTo(0, 0);
    }, []);


    const dispatch = useDispatch();
    const [isModalActive, setIsModalActive] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(false);
    const handleClose = () => setIsModalActive(false);

    const [addCartloader, setAddCartloader] = useState(false);
    const handleAddCart = async (product) => {
        setAddCartloader(true);
        setSelectedProduct(product);
        let body = {
            "url": product?.url,
            "quantity": 1
        }
        let response = localStorage.getItem("token") ? await postMethodWithToken(url.addCart, body) : await postMethod(url.addCart, body)
        // localStorage.getItem("token") ? await postMethod(url.addCart, body) : await postMethod(url.addCart, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                const basket = response.data.lines.split('/api/v1/')[1]
                toast.success("Item Added to cart");
                console.log("abc234",response?.data?.id)
                localStorage.setItem("basketId",response?.data?.id);
                if(getToken()){

                    dispatch(userCallApi(USER, {}, {
                        run: true,
                        handleFunction: (res) => handleCartCount(response?.data?.id)
                    }))
                }
                setIsModalActive(true);
                localStorage.setItem('cartAPI', basket)
                localStorage.setItem("clearAllCart", response.data.url)
                setAddCartloader(false);
            }
            // else if (response.status === 401) {
            //     toast.error("Invalid Token");
            //     navigate('/signin');
            //     setAddCartloader(false);
            // }
            else if (response.status === 406) {
                toast.error("Out Of Stock");
                setAddCartloader(false);
            } else {
                setAddCartloader(false);
            }
        // })

    }


    const handleCartCount = (basketId) => {
        dispatch(cartCallApi(CART, {}, {
            url: `ecom/baskets/${basketId}/lines`,
            run: true,
            handleFunction: (res) => handleSuccess(res)
        }))
    };
    const handleSuccess = (res) => {
        dispatch(commonCallApi(COMMON_DETAILS, { cart: res?.data?.[0]?.total_quantity }, { run: true }))
    };
    const [Models, setModels] = useState([])


    useEffect(() => {
        handleModels();
    
    }, [])
    const handleModels = async () => {
        let apiUrl = `${url.buy}?catagory=scooter&paginate=false&page_limit=0`;
        let response;
        response = localStorage.getItem("token") ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl)
        if (response.status === 200 || response.status === 201) {
            setModels(response.data)

        }
    }

    // Prevent back navigation
    useEffect(() => {
        const preventBack = () => {
            window.history.pushState(null, document.title, window.location.href);
        };

        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', preventBack);

        return () => {
            window.removeEventListener('popstate', preventBack);
        };
    }, []);
    // State to control visibility of the image once it's fully loaded
    const [isLoaded, setIsLoaded] = useState(false);  // State to track image load

    // Handle image load event
    const onImageLoad = () => {
        setIsLoaded(true);  // Set loaded state to true when the image is loaded
    };


    // banner list 
    const getBannerList = () => {
        dispatch(bannersListCallApi(BANNERS_LIST, {}, {
            run: true,
            handleFunction: (res) => { }
        }))
    };
    
   
    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible">
            <WrapperDiv style={{ fontFamily: "Manrope, sans-serif" }} className="Home" image={CardImage2}>
                <Container className="containerMain p-0" fluid  >
                    <div style={{ background: "#ffffff" }}>
                        {!bannerListLoader ?
                            <Carousel >
                                { bannerList?.map((data, index) => (
                                    <Carousel.Item key={index} onClick={() => navigate('/scooters')} style={{ cursor: 'pointer' }}>
                                        <img style={{ background: '' }} src={data?.image} className="d-block w-100" />
                                        {/* <LazyLoadImage
                                            alt="banner"
                                            src={data?.image}
                                            placeholderSrc={placeholderImage}
                                            height="fit-content"
                                            width="100vw"
                                            effect="blur"
                                        /> */}
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                            :
                            <Skeleton className="d-block w-100" variant="rectangular" width={'100vw'} height={'90vh'} />
                        }

                    </div>

                    {!loader ?
                        <>
                            <div className=" text-center">
                                <Box
                                    sx={{
                                        padding: {
                                            xs: '12px 0',
                                            sm: '24px 0',
                                            md: '48px 0',
                                            lg: '96px 0'
                                        }
                                    }}
                                    style={{ background: '#fafafa' }}>
                                    <Container >
                                        <Row>
                                            <Col md={12} className={'secondHeading'} >
                                                <Box
                                                    sx={{
                                                        marginBottom: {
                                                            xs: '12px',
                                                            sm: '24px',
                                                            md: '48px',
                                                        },

                                                    }}
                                                >
                                                    <H1text size={'36'} lineHeight={'36'} fontWeight={'600'}>What’s Your Model?</H1text>
                                                </Box>
                                            </Col>
                                            <Col md={12}>
                                                <div className=" d-flex flex-column justify-content-center">
                                                    <Box className=" d-flex flex-column justify-content-center p-0 " gap={5} style={{ backgroundColor: 'transparent' }}>
                                                        <Box className="d-flex  justify-content-center flex-wrap" gap={2} style={{ height: 'fit-content !important' }} >
                                                            {productList && productList.length > 0 && productList.map((data, index) => (
                                                                <Box key={index} href="#" className="text-dark">
                                                                    <button className="d-flex align-items-center navButton" gap={5} style={{

                                                                        padding: selectedSingleProduct?.id === data?.id ? '2px 24px 2px 2px' : '2px 24px 2px 2px', backgroundColor: selectedSingleProduct?.id === data.id ? '#4579F5' : '#f1f1f1',
                                                                        border: 'none', borderRadius: '12px', height: '48px'

                                                                    }} onClick={() => handleNavProducts(data)}>
                                                                        <div className="imgBox" style={{ borderRadius: '12px' }}>
                                                                            <img src={data?.images[0]?.original} className="me-2" style={{ width: '44px', height: '44px', background: 'white', borderRadius: '12px' }} />
                                                                        </div>
                                                                        <span className={`title ${selectedSingleProduct?.id === data?.id ? 'selected' : ''}`} >
                                                                            {data.title.trim().split(/\s+/).slice(0, 3).join(' ')}
                                                                        </span>

                                                                    </button></Box>
                                                            ))}

                                                        </Box>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            marginTop: {
                                                                xs: '12px',
                                                                sm: '24px',
                                                                md: '32px',
                                                            }
                                                            // marginTop:'32px'
                                                        }}
                                                    >

                                                        <Row style={{ background: '#ffffff', borderRadius: '24px', padding: '16px' }}>
                                                            <Col lg={6} className="justify-content-center" style={{ background: '#fafafa', borderRadius: '12px', padding: '0px 0px' }}><div className="carouselBuy" >
                                                                <Carousel interval={null} activeIndex={activeIndex || 0} onSelect={handleSelect}
                                                                    prevIcon={<div style={{ background: '#f6f6f6', padding: '36px 0', maxWidth: '24px', borderRadius: '12px' }}><ArrowBackIosIcon style={{ color: 'black', marginLeft: '7px' }} /></div>}
                                                                    nextIcon={<div style={{ background: '#f6f6f6', padding: '36px 0', maxWidth: '24px', borderRadius: '12px' }}><ArrowForwardIosIcon style={{ color: 'black', marginRight: '7px' }} /></div>}
                                                                >
                                                                    {selectedSingleProduct?.images?.length > 0 && selectedSingleProduct.images.map((data, dataIndex) => (
                                                                        <Carousel.Item key={dataIndex} className="text-center">
                                                                            {/* <img src={data?.original} className="d-block mx-auto" alt={`Slide ${itemIndex}-${dataIndex}`} style={{ width: '361px', height: '361px' }} /> */}
                                                                            <div style={{ marginTop: '20px' }}>
                                                                                {/* <WrapperImageDiv image={data?.original} >

                                                                        </WrapperImageDiv> */}
                                                                                <LazyLoadImage

                                                                                    alt="Description of image"
                                                                                    src={isLoaded ? data?.original : placeholderImage} // Show the placeholder image until the actual image is loaded
                                                                                    placeholderSrc={placeholderImage} // Optional: Placeholder image in case of lazy loading
                                                                                    // maxheight="240px"
                                                                                    // maxWidth="100%"
                                                                                    className={`mt-1 indicator-image ${activeIndex === dataIndex ? 'active' : ''}`}
                                                                                    style={{
                                                                                        opacity: isLoaded ? 1 : 0, // Fade-in effect after loading the actual image
                                                                                        transition: 'opacity 1s ease-in', // Smooth transition for the fade-in effect
                                                                                        cursor: 'pointer',
                                                                                        objectFit: 'cover',
                                                                                        // background: url(${props => props.image});
                                                                                        height: '346px',
                                                                                        width: '100%',
                                                                                        margin: 'auto',
                                                                                        marginTop: '20px'
                                                                                        // background-position: center;
                                                                                        //   background-size: contain;
                                                                                        //   background-repeat: no-repeat;
                                                                                    }}
                                                                                    effect="blur"
                                                                                    onLoad={onImageLoad} // Handle image load event
                                                                                />
                                                                            </div>
                                                                        </Carousel.Item>
                                                                    ))}
                                                                </Carousel>

                                                                <div className="d-flex justify-content-center mt-3">
                                                                    {selectedSingleProduct?.images && selectedSingleProduct?.images?.length > 0 && selectedSingleProduct?.images.map((image, index) => (
                                                                        // <img
                                                                        //     key={index}
                                                                        //     src={image?.original}
                                                                        //     alt={`Indicator ${index}`}
                                                                        //     className={`indicator-image ${activeIndex === index ? 'active' : ''}`}
                                                                        //     onClick={() => handleSelect(index)}
                                                                        //     style={{ cursor: 'pointer', width: '55px', height: '55px', objectFit: 'cover', margin: '0 5px', border: (activeIndex === index) ? '2px solid #4579F5' : 'none', borderRadius: '15%' }}
                                                                        // />
                                                                        <LazyLoadImage
                                                                            key={index}
                                                                            alt={`Indicator ${index}`}
                                                                            src={image?.original} // The actual image URL
                                                                            placeholderSrc={placeholderImage} // Placeholder image URL
                                                                            // height="auto"
                                                                            className={`indicator-image ${activeIndex === index ? 'active' : ''}`}
                                                                            style={{ cursor: 'pointer', width: '55px', height: '55px', objectFit: 'cover', margin: '0 5px', border: (activeIndex === index) ? '2px solid #4579F5' : 'none', borderRadius: '15%' }}
                                                                            // effect="blur" // Optional: adds a blur effect during loading
                                                                            onClick={() => handleSelect(index)}
                                                                        />
                                                                    ))}

                                                                </div>
                                                            </div></Col>

                                                            {productList && productList.length > 0 && productList.map((data, index) => (
                                                                data?.id === selectedSingleProduct?.id && (
                                                                    <Col key={index} lg={6} ><div className="product_description" style={{ marginTop: '32px' }} >
                                                                        <Stack direction="horizontal" className="align-items-center justify-content-between">
                                                                            <Box > <H1text size={'30'} lineHeight={'36'} fontWeight={'600'} marginTop={'0'} className="scooterName">{data?.title.trim().split(/\s+/).slice(0, 3).join(' ')}</H1text></Box>
                                                                            <Box className="d-flex justify-content-between align-items-center gap-1 ">   <Button variant="dark" className=" rounded-5" style={{ padding: '2px 12px' }}> <Ptext size={'14'} lineHeight={'24'} fontWeight={'600'} marginTop={'0'} >For the Urban Commuter</Ptext> </Button><span className="fw-bold ml-2" style={{ background: '#fafafa', borderRadius: '28px', padding: '4px 12px' }}>4/5</span></Box>

                                                                        </Stack>
                                                                        <Box sx={{
                                                                            marginTop: {
                                                                                xs: '4px',
                                                                                sm: '8px',
                                                                                md: '16pxpx',
                                                                                lg: '32px'
                                                                            }
                                                                        }}>
                                                                            <Row className="align-items-center text-start"  >
                                                                                <Col xs={12} lg={12} style={{ padding: '20px', background: '#fafafa', borderRadius: '12px' }}>
                                                                                    {/* <div className="justify-content-between align-items-baseline d-flex" key={index}>
                                                                                    <div className="d-flex align-items-center gap-2">
                                                                                        <img src={item?.icon} style={{ width: '15px', height: '15px', color: '#c5c5c5' }} alt="icon" />
                                                                                        <Ptext size={'16'} lineHeight={'24'} fontWeight={'600'} marginTop={'0'}>
                                                                                            {item?.name}
                                                                                        </Ptext>
                                                                                    </div>
                                                                                    <div gap={2} className="align-items-baseline pb-3 justify-content-end values d-sm-flex d-block">
                                                                                        <div className="mb-0 mx-2 brandValue">
                                                                                            <Ptext size={'16'} lineHeight={'24'} fontWeight={'600'} marginTop={'0'}>
                                                                                                {item?.value.trim().split(/\s+/).slice(0, 2).join('')}
                                                                                            </Ptext>
                                                                                        </div>
                                                                                        <div className="d-flex gap-2">
                                                                                            <img src="https://metrorides.theclientdemos.com/assets/images/blu.svg" alt="star" />
                                                                                            <img src="https://metrorides.theclientdemos.com/assets/images/blu.svg" alt="star" />
                                                                                            <img src="https://metrorides.theclientdemos.com/assets/images/blu.svg" alt="star" />
                                                                                            <img src="https://metrorides.theclientdemos.com/assets/images/gry.svg" alt="star" />
                                                                                            <img src="https://metrorides.theclientdemos.com/assets/images/gry.svg" alt="star" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div> */}
                                                                                    {data?.attributes && data?.attributes.length > 0 && data?.attributes.slice(0, 5).map((item, index) => (
                                                                                        <Row key={index} style={{
                                                                                            display: 'flex',
                                                                                            justifyContent: 'space-between',
                                                                                            marginTop: index != 0 && '12px'
                                                                                        }}>
                                                                                            <Col sm={6} style={{ display: "flex", alignItems: 'center' }}>
                                                                                                <img className='me-2' src={item?.icon} style={{ width: '15px', height: '15px', color: '#c5c5c5' }} alt="icon" />
                                                                                                <Ptext size={"14"} lineHeight={"24"} fontWeight={"600"} marginTop={"0"}
                                                                                                    style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}
                                                                                                > {item?.name}</Ptext>
                                                                                            </Col>
                                                                                            <Col sm={6} style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                                                                                <Box>
                                                                                                    <Ptext size={"14"} lineHeight={"24"} fontWeight={"700"} marginTop={"0"}
                                                                                                        style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}
                                                                                                    >{item?.value}</Ptext>
                                                                                                </Box>
                                                                                                <Rating rating={4} />
                                                                                                {/* <Rating rating={data?.rating} /> */}
                                                                                            </Col>
                                                                                        </Row>
                                                                                    ))}


                                                                                </Col>
                                                                            </Row>

                                                                        </Box>
                                                                    </div>
                                                                        <Stack direction="vertical" gap={3} className="productButtons w-100" style={{ marginTop: '32px', marginBottom: '32px' }}>
                                                                            <Button variant="outline-dark" className=" w-100 fw-bold CartButton" style={{ borderRadius: '12px', padding: '11px' }}>
                                                                                <Ptext size={'16'} lineHeight={'24'} fontWeight={'600'} marginTop={'0'}
                                                                                    style={{ display: 'flex', justifyContent: 'center' }}
                                                                                    onClick={() => handleAddCart(data)}>
                                                                                    {(selectedProduct?.id == data?.id && addCartloader) ? <CircularProgress style={{ 'color': '#808080', 'width': '24px', 'height': '24px' }} /> :
                                                                                        <>Buy&nbsp;/&nbsp;{data?.price_incl_tax}</>}
                                                                                </Ptext>
                                                                            </Button>
                                                                            <Button variant="dark" className=" w-100 fw-bold rentButton" style={{ borderRadius: '12px', padding: '11px' }}><Ptext size={'16'} lineHeight={'24'} fontWeight={'600'} marginTop={'0'}>Rent / ${data?.rent}</Ptext></Button>
                                                                        </Stack>
                                                                    </Col>)
                                                            ))}

                                                        </Row>
                                                    </Box>
                                                    <CommonModal
                                                        padding={'32px 40px'}
                                                        width={'736px'}
                                                        modalActive={isModalActive}
                                                        handleClose={handleClose}
                                                        innerContent={
                                                            <InnerContent productDetails={selectedProduct} handleClose={handleClose} />
                                                        }
                                                    />
                                                </div>

                                            </Col>
                                        </Row>
                                    </Container>
                                </Box>
                            </div>
                        </>
                        :
                        <>
                            <Box
                                sx={{
                                    padding: {
                                        xs: '12px 0',
                                        sm: '24px 0',
                                        md: '48px 0',
                                        lg: '96px 0'
                                    }
                                }}
                                style={{ background: '#fafafa' }}>
                                <Container>
                                    <Row>
                                        {/* Heading Skeleton */}
                                        <Col md={12} className="secondHeading">
                                            <Skeleton variant="text" width="50%" height={36} style={{ margin: '0 auto' }} />
                                        </Col>

                                        {/* Product List Skeleton */}
                                        <Col md={12}>
                                            <div className="d-flex flex-column justify-content-center">
                                                <Box className="d-flex flex-column justify-content-center p-0" gap={5} style={{ backgroundColor: 'transparent' }}>
                                                    <Box className="d-flex justify-content-center flex-wrap" gap={2} style={{ height: 'fit-content' }}>
                                                        {[1, 2, 3, 4].map((_, index) => (
                                                            <Skeleton variant="rectangular" width={64} height={48} key={index} style={{ margin: '8px', borderRadius: '12px' }} />
                                                        ))}
                                                    </Box>
                                                </Box>

                                                {/* Product Details Skeleton */}
                                                <Box
                                                    sx={{
                                                        marginTop: {
                                                            xs: '12px ',
                                                            sm: '24px ',
                                                            md: '48px ',
                                                        }
                                                    }}
                                                >
                                                    <Row style={{ background: '#ffffff', borderRadius: '24px', padding: '16px' }}>
                                                        <Col lg={6} className="justify-content-center" style={{ background: '#fafafa', borderRadius: '12px', padding: '20px' }}>
                                                            <Skeleton variant="rectangular" width="100%" height={361} style={{ borderRadius: '12px' }} />
                                                            <Box mt={3} display="flex" justifyContent="center" gap={2}>
                                                                {[1, 2, 3, 4].map((_, index) => (
                                                                    <Skeleton variant="rectangular" width={55} height={55} key={index} style={{ margin: '8px', borderRadius: '12px' }} />
                                                                ))}
                                                            </Box>
                                                        </Col>

                                                        <Col lg={6}>
                                                            <Stack direction="vertical" gap={3} className="w-100" style={{ marginTop: '32px' }}>
                                                                {/* Product Name Skeleton */}
                                                                <Skeleton variant="text" width="60%" height={30} style={{ margin: '0 auto' }} />

                                                                {/* Attributes Skeleton */}
                                                                {[1, 2, 3].map((_, index) => (
                                                                    <Box display="flex" justifyContent="space-between" key={index} style={{ padding: '8px 0' }}>
                                                                        <Skeleton variant="text" width="30%" height={20} />
                                                                        <Skeleton variant="text" width="50%" height={20} />
                                                                    </Box>
                                                                ))}

                                                                {/* Buttons Skeleton */}
                                                                <Box mt={3} display="flex" flexDirection="column" gap={2}>
                                                                    <Skeleton variant="rectangular" width="100%" height={36} style={{ borderRadius: '12px' }} />
                                                                    <Skeleton variant="rectangular" width="100%" height={36} style={{ borderRadius: '12px' }} />
                                                                </Box>
                                                            </Stack>
                                                        </Col>
                                                    </Row>
                                                </Box>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Box>
                        </>
                    }
                    <div className=" text-center ">

                        <Box
                            className='ps-3 pe-3'
                            sx={{
                                paddingBottom: {
                                    xs: '12px ',
                                    sm: '24px ',
                                    md: '48px ',
                                    lg: '96px '
                                }
                            }}
                            style={{ background: '#ffffff' }}>
                            <Container  >
                                <Box sx={{
                                    paddingTop: {
                                        xs: '12px ',
                                        sm: '24px ',
                                        md: '48px ',
                                        lg: '96px '
                                    }
                                }} >
                                    <Box sx={{
                                        marginBottom: {
                                            xs: '12px ',
                                            sm: '24px ',
                                            md: '48px ',
                                        }
                                    }}> <H1text size={'36'} lineHeight={'36'} fontWeight={'600'} marginTop={'0'} style={{ textAlign: 'left' }}>What do we offer?</H1text></Box>
                                    <Row style={{ display: 'flex' }} className="g-5">
                                        <Col sm={6}>
                                            <Row className="g-3 text-left">
                                                <Col sm={12} className='firstImage' style={{ padding: '32px' }}>
                                                    <H1text size={'24'} lineHeight={'32'} fontWeight={'600'} color={'#ffffff'}>
                                                        Warranty
                                                    </H1text>
                                                    <div className="textWidth">
                                                        <Ptext size={'14'} lineHeight={'24'} fontWeight={'400'} color={'#ffffff'}>
                                                            All rented scooters, and scooters purchased within 1 year are entitled to a full parts warranty. The replacement parts will be shipped and sent free of charge
                                                        </Ptext>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={6} className={'mt-3 mt-sm-5'} >
                                            <Row className="g-3 text-left">
                                                <Col sm={12} className='secondImage' style={{ padding: '32px', display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
                                                    <H1text size={'24'} lineHeight={'32'} fontWeight={'600'} color={'#ffffff'}>
                                                        Delivery
                                                    </H1text>
                                                    <div className="textWidth">
                                                        <Ptext size={'14'} lineHeight={'24'} fontWeight={'400'} color={'#ffffff'}>
                                                            We’ll ship your new ride right to you!
                                                        </Ptext>
                                                    </div>
                                                </Col>
                                                <Col sm={12} className='thirdImage' style={{ padding: '32px', display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
                                                    <H1text size={'24'} lineHeight={'32'} fontWeight={'600'} color={'#ffffff'}>
                                                        Insurance
                                                    </H1text>
                                                    <div className="textWidth" style={{ maxWidth: '283px' }}>
                                                        <Ptext size={'14'} lineHeight={'24'} fontWeight={'400'} color={'#ffffff'}>
                                                            All scooters with a proper police report, proof of theft, within warranty are eligible for a replacement, you just pay off shipping
                                                        </Ptext>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                </Box>
                            </Container>
                        </Box>
                        <div style={{ background: "#FAFAFA", padding: '0' }} className="ps-3 pe-3">
                            <PublicTransport />
                            <ModerApp />
                        </div>
                        <div>
                            <Reviews  />
                        </div>
                        <ErrorBoundary>
                            <Suspense fallback={<div>loading---</div>}>
                                <Box >
                                    <Questions />
                                </Box>
                            </Suspense>
                        </ErrorBoundary>
                    </div>
                </Container>
                <Box  >
                    <PerfectScooter
                        heading={'Your own perfect scooter'}
                        for={'Scooter'}
                        image={'https://metrorides.theclientdemos.com/assets/images/cta.png'}
                        top={'-96px'}
                        models={Models}
                    />
                </Box>
            </WrapperDiv>
        </motion.div>
    )
}

export default Home