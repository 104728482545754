import React, { useState } from "react";
import { Container, Row, Col, FloatingLabel, Form, Button } from "react-bootstrap";
import "components/common/perfectScooter/perfectScooter.scss"
import H1text from "components/H1text/H1text";
import Ptext from "components/Ptext/Ptext";
import PlaceholderInput from "components/Inputs/PlaceholderInput/PlaceholderInput";
import BasicInput from "components/Inputs/BasicInput/BasicInput";
import { validateEmail, validatePhoneNumber } from "services/common";
import { Box } from "@mui/material";
import AppleIcon from '@mui/icons-material/Apple';

const PerfectScooter = (props) => {

    const typeData = [
        {
            slug:"one",
            name:"One"
        },
        {
            slug:"two",
            name:"Two"
        },
        {
            slug:"three",
            name:"Three"
        },
    ]
    const [formValue, setFormvalue] = useState({
        "type": "",
        "email": "",
        "phone": "",
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormvalue((prev) => ({
            ...prev,
            [name]: value
        }));
    };
    return (
        <Box
        sx={{
            paddingBottom: {
              xs: '12px',
              sm: '24px',
              md: '48px',
              lg:'96px'
            },
          }}
         className="perfectScooter" style={{ backgroundColor: props.bgColor? props.bgColor :'#fafafa' }}  >
            <Container className=" containerScooter p-0 pe-3 ps-3 pe-md-0 ps-md-0">
                <Box sx={{
                  padding: {
                    xs: '24px',
                    sm: '24px',
                    md: '24px',
                    lg:'48px'
                  },
                }}
                style={{ background: "#ECF1F4", borderRadius: "30px" }}
                >
                <Row >
                    <Col md={7}>
                        <div>
                            <H1text size={'36'} lineHeight={'36'} fontWeight={'600'} className="text-black" marginTop={0} style={{ maxWidth: '309px' }}>{props.heading}</H1text>
                            <div className="form_box" style={{ marginTop: '38px' }}>
                                <form>
                                    <Row>
                                        <Col md={6} className='max280' style={{ marginBottom: '20px' }} >
                                            <PlaceholderInput
                                                label={props.for}
                                                placeholder={props.for}
                                                data={props?.models}
                                                value={formValue?.type}
                                                name={"type"}
                                                onChange={(e) => handleChange(e)}
                                            />
                                        </Col>
                                        {/* <Col md={6} style={{ marginBottom: '20px' }} >
                                            <FloatingLabel controlId="floatingSelect" label={props.for} className="label" >
                                                <Form.Select aria-label="Floating label select example" className="backgroundForm backgroundFormOption" >
                                                    <option>
                                                        Open this select menu</option>
                                                    <option value="1">
                                                        One</option>
                                                    <option value="2">
                                                        Two</option>
                                                    <option value="3">
                                                        Three</option>
                                                </Form.Select>
                                            </FloatingLabel></Col> */}
                                        <Col md={6} className='max280' style={{ marginBottom: '20px'}}>
                                            {/* <input type='text' placeholder={'Your name'} name='name' autoFocus className="form-control backgroundForm" /> */}
                                            <BasicInput
                                                            label="Your name"
                                                            name="Your name"
                                                            type="text"
                                                            // value={city}
                                                            // onChange={(e) => setCity(e.target.value)}
                                                            // style={{ marginTop: '20px' }}
                                                        />
                                        </Col>
                                        <Col md={6} className='max280' style={{ marginBottom: '20px' }}>
                                            {/* <input type='email' placeholder='Your email' name='name' autoFocus className="form-control backgroundForm" /></Col> */}
                                            <BasicInput
                                                label="Your email"
                                                name="email"
                                                type="email"
                                                value={formValue?.email}
                                                onChange={handleChange}
                                                error={formValue?.email!=""?!validateEmail(formValue?.email):""}
                                            />
                                            </Col>
                                        {/* <Col md={6} style={{ marginBottom: '20px' }}><input type='text' placeholder='Your phone' name='name' autoFocus className="form-control backgroundForm" /></Col> */}
                                        <Col md={6} className='max280' style={{ marginBottom: '20px' }}>
                                        <BasicInput
                                                label="Your phone"
                                                name="phone"
                                                type="tel"
                                                value={formValue?.phone}
                                                onChange={handleChange}
                                                maxLength={10}
                                                error={formValue?.phone!=""?!validatePhoneNumber(formValue?.phone):""}
                                            />
                                        </Col>
                                        <Col md={6} className="mb-3 mb-md-0 max280" >  <Button variant="dark" className=" orderButton w-100" type="submit">  <Ptext size={'16'} lineHeight={'24'} fontWeight={'600'} marginTop={'0'}>Order right now</Ptext></Button></Col>
                                        <Col md={6} className='max280'  > <button className="payButton w-100 d-flex justify-content-center gap-1">
                                            {/* <img src="https://metrorides.theclientdemos.com/assets/images/apple_music.svg" /> */}
                                            <AppleIcon/>
                                            <Ptext size={'16'} lineHeight={'24'} fontWeight={'600'} marginTop={'0'}>Apple pay</Ptext></button></Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </Col>
                    <Col md={5}>
                        <div className="scooterImage d-none d-md-block" style={{ position: 'relative' }} >
                            <Box  sx={{
                                        position:{
                                            xs:'none',
                                            sm:'none',
                                            md:'none',
                                            lg:'absolute'
                                        } , 
                                        top: props.top
                                    }}>
                            <img src={props.image}  />
                            </Box>
                            </div></Col>
                </Row>
                </Box>
            </Container>
        </Box>
    )

}
export default PerfectScooter