import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';

// Styled TextField with custom styles
const CustomTextField = styled(TextField)`
  & .MuiFilledInput-root {
    border: none;
    border-radius: 4px;
    &:before,
    &:hover:before,
    &:after {
      border-bottom: none;
      content: none;
    }
  }
  .MuiInputBase-root {
    border-radius: 12px;
    background: transparent;
    border: 1px solid #dfdfdf;
    max-height: 48px;
    ${({ error }) => error && `
      border: 1px solid red;
    `}
  }
  .MuiInputBase-input {
    padding: 14px 16px !important;
    margin-top: 16px;
  }
  .MuiFormLabel-root {
    padding-left: 6px !important;
    padding-right: 6px !important;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: 'Manrope';
  }
  .MuiFormLabel-root[data-shrink='true'] {
    color: #1d1d1d;
    ${({ error }) => error && `
      color: red;
    `}
  }
  .MuiFormLabel-root[data-shrink='false'] {
    color: #757575;
  }
`;


export default function BasicInput({ label, type,error, onChange, name, ...props }) {
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Box
      component="form"
      sx={{ '& > :not(style)': { width: '100%' } }}
      noValidate
      autoComplete="off"
      
    >
      <CustomTextField
        id={`${label.toLowerCase().replace(/\s+/g, '-')}-input`}
        variant="filled"
        label={label}
        // value={value}
        name={name}
        onChange={onChange?onChange:handleChange}
        type={type === 'cvv' ? 'password' : 'text'}
        error={error} // Pass the error prop here
        inputProps={{
          maxLength:
            type === 'cardNumber' ? 19 : type === 'expiry' ? 5 : type === 'tel'? 10 : type ==='postalCode'?6:'',
            inputMode: 'numeric', pattern: '[0-9]*'
        }}
        {...props}
      />
    </Box>
  );
}
