import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import 'pages/orderDetail/orderDetail.scss';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import H1text from "components/H1text/H1text";
import Ptext from "components/Ptext/Ptext";
import { Box } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import placeholderImage from 'assets/images/placeholderImage.png';
import vehicle from 'assets/images/vehicle.png';
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

const OrderDetail = () => {

    const location = useLocation();
    console.log("abc", location?.state?.item)
    const orderList = useSelector((state) => state?.orderDetailsReducer?.payload?.data);
    console.log("object2222d", orderList);
    return (
        <div className="orderDetail ps-3 pe-3">
            <Container>
                <H1text size={'36'} lineHeight={'39.6'} fontWeight='600' marginTop={'0'}>Order Details</H1text>
                <Row style={{ marginTop: "35px" }}>
                    <Col sm={10} md={8}>
                        <div className="custom-font">
                            <p className="delivery">Delivered
                                <span className="deliveryDate ps-2">On 12 Aug 2024, Sunday</span>
                            </p>
                            <div className="d-md-flex gap-4">
                                <p className="order">Order placed on:
                                    <span className="orderDate ps-2">{moment(location?.state?.item?.date_placed).format('DD MMM YYYY, dddd')}</span>
                                </p>
                                <p className="order">Order ID:
                                    <span className="orderDate">{location?.state?.item?.number}</span>
                                </p>
                            </div>

                        </div>
                    </Col>
                    <Col sm={10} md={4} className="mb-2 d-flex justify-content-end">
                        <Button style={{ maxWidth: '256px', height: '56px', borderRadius: '8px' }} variant="outline-dark" className="d-flex align-items-center gap-2 justify-content-center pt-2 pb-2 w-100">
                            <FileDownloadOutlinedIcon />
                            <p className="invoice mb-0">Invoice</p>

                        </Button>
                    </Col>
                </Row>

                <Row style={{ border: '1px solid #eaeaea', padding: '24px', borderRadius: '20px', marginTop: '8px' }}>
                    <Col xs={12} md={6} lg={4}>
                        <Ptext size={'18'} lineHeight={'19.8'} fontWeight='700' marginTop={'0'}>Payment Method</Ptext>
                        <Ptext size={'16'} lineHeight={'24'} fontWeight='600' marginTop={'12'} color={'#959595'}>
                            Credit Card
                        </Ptext>

                    </Col>
                    <Col xs={12} md={6} lg={4} className="mt-3 mt-md-0">
                        <Ptext size={'18'} lineHeight={'19.8'} fontWeight='700' marginTop={'0'}>Delivery Address</Ptext>
                        <Ptext size={'16'} lineHeight={'24'} fontWeight='600' marginTop={'12'} color={'#959595'}>
                            {/* Lydia Douglas */}
                            {location?.state?.item?.shipping_address?.first_name + " " + location?.state?.item?.shipping_address?.last_name}
                        </Ptext>
                        <Ptext size={'16'} lineHeight={'24'} fontWeight='600' marginTop={'12'} color={'#959595'}>
                            {/* 519 River Street, West Garfield 85252-2399 */}
                            {(location?.state?.item?.shipping_address?.line1 || "") +
                                " " +
                                (location?.state?.item?.shipping_address?.line2 || "") +
                                " " +
                                (location?.state?.item?.shipping_address?.line3 || "") +
                                " " +
                                (location?.state?.item?.shipping_address?.line4 || "")}

                        </Ptext>
                    </Col>
                    <Col xs={12} md={6} lg={4} className="mt-3 mt-md-0">
                        <Ptext size={'18'} lineHeight={'19.8'} fontWeight='700' marginTop={'0'}>Order summary</Ptext>
                        <Box display='flex' justifyContent={'space-between'}>
                            <Ptext size={'16'} lineHeight={'24'} fontWeight='400' marginTop={'12'} color={'#1a1b1f'}>
                                Items({location?.state?.item?.quantity})
                            </Ptext>
                            <Ptext size={'16'} lineHeight={'24'} fontWeight='600' marginTop={'12'} color={'#959595'}>
                                ${location?.state?.item?.total_excl_tax}
                            </Ptext>
                        </Box>
                        <Box display='flex' justifyContent={'space-between'}>
                            <Ptext size={'16'} lineHeight={'24'} fontWeight='600' marginTop={'12'} color={'#1a1b1f'}>
                                Tax
                            </Ptext>
                            <Ptext size={'16'} lineHeight={'24'} fontWeight='600' marginTop={'12'} color={'#959595'}>
                                $0.00
                            </Ptext>
                        </Box>
                        <Box display='flex' justifyContent={'space-between'}>
                            <Ptext size={'16'} lineHeight={'24'} fontWeight='400' marginTop={'12'} color={'#1a1b1f'}>
                                Packing charge
                            </Ptext>
                            <Ptext size={'16'} lineHeight={'24'} fontWeight='600' marginTop={'12'} color={'#959595'}>
                                $0.00
                            </Ptext>
                        </Box>
                        <Box display='flex' justifyContent={'space-between'}>
                            <Ptext size={'16'} lineHeight={'24'} fontWeight='400' marginTop={'12'} color={'#1a1b1f'}>
                                Shipping
                            </Ptext>
                            <Ptext size={'16'} lineHeight={'24'} fontWeight='600' marginTop={'12'} color={'#959595'}>
                                ${location?.state?.item?.shipping_incl_tax}
                            </Ptext>
                        </Box>
                        <Box display='flex' justifyContent={'space-between'}>
                            <Ptext size={'20'} lineHeight={'30'} fontWeight='600' marginTop={'12'} color={'#1a1b1f'}>
                                Total
                            </Ptext>
                            <Ptext size={'20'} lineHeight={'30'} fontWeight='600' marginTop={'12'} color={'#1a1b1f'}>
                                ${location?.state?.item?.total_incl_tax}
                            </Ptext>
                        </Box>
                    </Col>
                </Row>

                <Box style={{ marginTop: '32px' }}>
                    <Ptext size={'18'} lineHeight={'19.7'} fontWeight='700' marginTop={'0'}>
                        Items included in the order
                    </Ptext>
                </Box>
                {/* Array.from(new Array(6)).map((_, index) => ( */}
                <Row className="g-3" style={{ paddingBottom: "198px" }}>
                    {orderList?.length > 0 && orderList.map((data, index) => (
                        <Col md={6} lg={4}
                        >
                            <div style={{
                                display: 'flex',
                                border: '1px solid #eaeaea',
                                padding: '16px 24px',
                                borderRadius: '20px',
                                marginTop: '8px'
                            }}>
                                <LazyLoadImage
                                    alt="Description of image"
                                    src={data?.product_images?.[0]?.original}
                                    placeholderSrc={placeholderImage}
                                    height="96px"
                                    width="96px"
                                    effect="blur"
                                />
                                <Box marginLeft={'24px'} display='flex' flexDirection={'column'} justifyContent='center'>
                                    <Ptext size={'20'} lineHeight={'20'} fontWeight='700' marginTop={'0'}>
                                        {data?.title}
                                    </Ptext>
                                    <Ptext size={'16'} lineHeight={'17.6'} fontWeight='400' marginTop={'16'}>
                                        Item Price: ${parseInt(data?.price_excl_tax_excl_discounts)}
                                    </Ptext>
                                </Box>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>

        </div>
    )
}
export default OrderDetail