import { takeLatest, put, take } from "redux-saga/effects";
import { COUNTRIES, COUNTRIES_ERROR, COUNTRIES_SUCCESS } from "./constant";
import url from 'services/urls.json';
import { getMethod } from "services/apiServices";
import { ClearTokenRedirectLogin } from "context/context";


function* getCountriesApi(action) {

    try {
        const response = yield getMethod(url.countries);
        if (response.status === 200 || response.status === 201) {
            yield put({ type: COUNTRIES_SUCCESS, payload: response });

            if (action.external && action.external.redirect === true) {
                action.external.redirectNavigate(action.external.path);
            } else if (action.external && action.external.run === true) {
                action.external.handleFunction(response);
            } else if (action.external && action.external.step) {
                action.external.handleStep(action.external.step);
            }
        }
        else {
            yield put({ type: COUNTRIES_ERROR, payload: response });
            if(response.status === 401 || response.status === 403){
                ClearTokenRedirectLogin(action.external.navigate)
              }
            if (action.external && action.external.toast) {
                action.external.handleToastError(response)
            }
        }
    }
    catch (error) {
        yield put({ type: COUNTRIES_ERROR, payload: error })
    }
}

function* getCountriesSaga() {
    yield takeLatest(COUNTRIES, getCountriesApi);
}

export default getCountriesSaga;